<template>
  <v-dialog max-width="600px" persistent v-model="active">
    <v-card class="mx-auto">
      <v-toolbar flat color="black" :dark="true">
        <v-toolbar-title>Dodawanie towaru</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-card-text class="py--0">
        <v-row align="center" class="mx-0">
          <v-col cols="12">
            <v-select
              v-model="type"
              :items="select"
              item-value="value"
              item-text="label"
              label="typ towaru"
            ></v-select>
          </v-col>
        </v-row>

        <v-row align="center" v-if="type === 'hard'" class="mx-0">
          <v-col cols="12">
            <v-select
              v-model="selectedItem"
              :items="itemsList"
              item-value="value"
              :item-text="createLabel"
              label="wybierz z cennika"
              @change="fillInForm()"
              return-object
            ></v-select>
          </v-col>
        </v-row>

        <v-form
          v-show="type === 'hard' && selectedItem"
          ref="hardForm"
          v-model="validHard"
          lazy="true"
          class="pl-4 mt-4"
        >
          <h3 class="grey--text text--darken-2">Meble twarde</h3>

          <v-row align="center" justify="center" class="mx-0">
            <!-- <v-col cols="11" sm="8" class="mb-0 pb-0">
              <v-text-field v-model="newPackage.position" disabled label="pozycja z cennika" name="position" type="text"/>
            </v-col>
            <v-col cols="11" sm="4" class="mb-0 pb-0">
              <v-text-field v-model="newPackage.price" disabled label="cena brutto" name="price"  type="number" suffix="zł" min="0"/>
            </v-col> -->
            <v-col cols="12" sm="7" class="my-0 py-0">
              <v-text-field
                v-model="newPackage.name"
                :rules="rulesContent"
                label="nazwa"
                name="name"
                type="text"
              />
            </v-col>
            <v-col cols="12" sm="5" class="my-0 py-0">
              <v-text-field
                v-model="newPackage.color"
                :rules="rulesContent"
                label="wybarwienie"
                name="color"
                type="text"
              />
            </v-col>
            <v-col cols="12" sm="4" class="my-0 py-0">
              <v-text-field
                v-model="newPackage.volume"
                :rules="floatWithoutZeroRule"
                label="objętość"
                name="volume"
                type="number"
                suffix="m³"
                min="0"
              />
            </v-col>
            <v-col cols="12" sm="4" class="my-0 py-0">
              <v-text-field
                v-model="newPackage.weight"
                :rules="floatWithoutZeroRule"
                label="waga"
                name="weight"
                type="number"
                suffix="kg"
                min="0"
              />
            </v-col>
            <v-col cols="12" sm="4" class="my-0 py-0">
              <v-text-field
                v-model="newPackage.number"
                :rules="integerPositiveWithoutZero"
                label="w ilu paczkach"
                name="number"
                type="number"
                suffix="szt"
                min="1"
              />
            </v-col>
            <v-divider class="mx-2 mb-4"></v-divider>
          </v-row>
        </v-form>

        <v-form
          v-show="type !== 'hard'"
          ref="otherForm"
          v-model="validOther"
          lazy="true"
          class="pl-4 mt-4"
        >
          <h3 class="grey--text text--darken-2">Meble tapicerowane i inne</h3>

          <v-row align="center" justify="center" class="mx-0">
            <v-col cols="12" sm="7" class="my-0 py-0">
              <v-text-field
                v-model="newPackage.name"
                :rules="rulesContent"
                label="nazwa"
                name="name"
                type="text"
              />
            </v-col>
            <v-col cols="12" sm="5" class="my-0 py-0">
              <v-text-field
                v-model="newPackage.color"
                :rules="rulesContent"
                label="wybarwienie"
                name="color"
                type="text"
              />
            </v-col>
            <v-col cols="12" sm="3" class="my-0 py-0">
              <v-text-field
                v-model="newPackage.width"
                :rules="integerPositiveWithoutZero"
                label="szerokość"
                name="width"
                type="number"
                suffix="cm"
                min="1"
              />
            </v-col>
            <v-col cols="12" sm="3" class="my-0 py-0">
              <v-text-field
                v-model="newPackage.height"
                :rules="integerPositiveWithoutZero"
                label="długość"
                name="height"
                type="number"
                suffix="cm"
                min="1"
              />
            </v-col>
            <v-col cols="12" sm="3" class="my-0 py-0">
              <v-text-field
                v-model="newPackage.depth"
                :rules="integerPositiveWithoutZero"
                label="wysokość"
                name="depth"
                type="number"
                suffix="cm"
                min="1"
              />
            </v-col>
            <v-col cols="12" sm="3" class="my-0 py-0">
              <v-text-field
                v-model="newPackage.weight"
                :rules="floatWithoutZeroRule"
                label="waga"
                name="weight"
                type="number"
                suffix="kg"
                min="0"
              />
            </v-col>
            <v-divider class="mx-2 mb-4"></v-divider>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col cols="12" sm="3">
          <v-btn
            block
            @click="disableDialog()"
            color="white"
            class="grey--text text--darken-4"
            :x-large="true"
            >Anuluj</v-btn
          >
        </v-col>
        <v-spacer />
        <v-col cols="12" sm="3">
          <v-btn
            v-if="type === 'hard'"
            block
            @click="
              createPackage('hard');
              disableDialog();
            "
            :disabled="!validHard"
            color="amber accent-3"
            class="grey--text text--darken-4"
            :x-large="true"
            v-tilt
          >
            Dodaj
          </v-btn>
          <v-btn
            v-else
            block
            @click="
              createPackage('other');
              disableDialog();
            "
            :disabled="!validOther"
            color="amber accent-3"
            class="grey--text text--darken-4"
            :x-large="true"
            v-tilt
          >
            Dodaj
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import axios from 'axios';

  export default {
    name: 'PackageCreator',
    props: {
      active: Boolean,
    },
    data: () => ({
      rulesContent: [v => !!v || 'Pole jest wymagane'],
      integerPositiveWithoutZero: [
        v => !!v || 'Pole jest wymagane',
        v => /^([1-9]\d*)$/.test(v) || 'Musi być liczbą naturalną',
      ],
      floatWithoutZeroRule: [
        v => !!v || 'Pole jest wymagane',
        v =>
          /^([1-9]\d*(\.|,)\d+|0(\.|,)0*[1-9]+\d*|[1-9]\d*)$/.test(v) ||
          'Liczba ma niepoprawny format (liczba musi być większa od 0)',
      ],
      select: [
        {
          label: 'Meble twarde',
          value: 'hard',
        },
        {
          label: 'Meble tapicerowane i inne',
          value: 'other',
        },
      ],
      itemsList: [], // array of categories
      selectedItem: null,
      newPackage: {},
      validHard: false,
      validOther: false,
      type: 'hard',
      settingsDefault: [],
    }),
    watch: {
      active: function (val) {
        if (val == false) {
          this.clear();
        }
      },
      type: function (val) {
        if (val === 'hard') {
          this.selectedItem = null;
          this.clear();
        } else {
          this.newPackage = {
            name: '',
            color: '',
            width: null,
            height: null,
            depth: null,
            weight: null,
          };
          this.$refs.otherForm.validate();
        }
      },
    },
    methods: {
      createLabel: item => item.label + '  (' + item.price + ' zł)',
      createPackage: function (type) {
        this.$emit('createPackage', this.newPackage, type);
        this.clear();
      },
      fillInForm: function () {
        this.newPackage = Object.assign({}, this.selectedItem);
        this.$refs.hardForm.validate();
      },
      disableDialog: function () {
        this.$emit('disableDialog');
      },
      getSettings: function () {
        axios({
          url: this.$store.state.url + '/api/transport/get-settings',
          data: {
            token: this.$store.state.token,
            meta: 'price_table',
          },
          method: 'POST',
        }).then(resp => {
          this.itemsList = [];

          for (let [key, value] of Object.entries(resp.data)) {
            this.itemsList.push({ header: key, value: key });
            value.forEach(elem => {
              this.itemsList.push({
                category: key,
                label: elem.label,
                position: `${elem.label} (${key})`,
                volume: elem.volume,
                weight: elem.weight,
                price: elem.value,
                number: elem.number,
              });
            });
          }

          let tmp = JSON.parse(JSON.stringify(this.itemsList));

          this.settingsDefault = tmp ? tmp : [];
        });
      },
      clear: function () {
        // clear
        this.type = 'hard';
        this.newPackage = {};
        this.selectedItem = null;
        this.itemsList = this.settingsDefault;
      },
    },
    created() {
      this.getSettings();
    },
  };
</script>
