<template>
  <v-container fluid class="grey lighten-4" style="height: 100%">
    <v-row justify="center">
      <v-col>
        <v-card-title>
          Transport {{ transport.t_number }}
          <v-spacer></v-spacer>
        </v-card-title>

        <v-expansion-panels multiple v-model="panels">
          <!-- summary new format -->
          <v-expansion-panel v-if="newFormat">
            <v-expansion-panel-header>
              {{ transport.t_number }} - Podsumowanie
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-row v-if="sender && receiver" align="start" justify="space-between">
                <v-col cols="12">
                  <div class="map" id="fullMap" />
                </v-col>
              </v-row>

              <v-row align="start" justify="space-between" dense>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title> Numer transportu: </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ transport.t_number }}
                        <v-icon v-if="isAdmin && transport.streamsoft_transport_id" small>
                          mdi-check-circle-outline
                        </v-icon>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6">
                  <v-list-item v-if="transport.extension" two-line>
                    <v-list-item-content>
                      <v-list-item-title> Numer własny: </v-list-item-title>
                      <v-list-item-subtitle> {{ transport.extension }} </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title> Dodano: </v-list-item-title>
                      <v-list-item-subtitle> {{ transport.created }} </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        <v-chip
                          v-if="isAdmin"
                          :color="getStatusColor(transport.status)"
                          @click="editStatus = !editStatus"
                          dark
                        >
                          {{ getStatusName(transport.status) }}
                        </v-chip>

                        <v-chip v-else :color="getStatusColor(transport.status)" dark>
                          {{ getStatusName(transport.status) }}
                        </v-chip>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="12">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>
                        Zawartość:
                        <v-btn v-if="isAdmin" icon @click="editItemsDialog = true">
                          <v-icon> mdi-package-variant </v-icon>
                        </v-btn>

                        <v-btn v-else icon disabled>
                          <v-icon> mdi-package-variant </v-icon>
                        </v-btn>
                      </v-list-item-title>

                      <v-data-table
                        :headers="headers"
                        :items="transport.params"
                        item-key="index"
                        show-expand
                        class="elevation-0"
                        disable-filtering
                        disable-sort
                        hide-default-footer
                        disable-pagination
                        dense
                        no-data-text="Lista jest pusta"
                        expand-icon=""
                        :items-per-page="-1"
                      >
                        <template v-slot:[`item.category_id`]> Przedmiot </template>

                        <template v-slot:[`item.item_type`]="{ item }">
                          {{ itemTypeMapper(item.item_type) }}
                        </template>

                        <template v-slot:[`item.quantity`]="{ item }">
                          {{ item.quantity }}
                        </template>

                        <template v-slot:[`item.name`]="{ item }">
                          {{ `${item.name}` }}
                          {{ isAdmin ? '' : '' }}
                          {{ '/' }}
                          {{ variantFormatName(item) }}
                        </template>

                        <template v-slot:[`item.color`]="{ item }">
                          {{ item.color }}
                        </template>

                        <template v-slot:[`item.packages.length`]="{ item }">
                          {{ item.packages.length }} szt.
                        </template>

                        <template v-slot:[`item.data-table-expand`]="{ item, expand, isExpanded }">
                          <v-btn icon small color="primary" @click="expand(!isExpanded)">
                            <v-icon> {{ `mdi-chevron-${isExpanded ? 'up' : 'down'}` }} </v-icon>
                          </v-btn>

                          <template v-if="isAdmin">
                            <v-btn
                              v-if="
                                item.received == true ||
                                parseInt(item.received) >= parseInt(item.quantity)
                              "
                              icon
                              small
                              color="success"
                              @click="
                                editConfirmItemsDialog = true;
                                editConfirmItem = item;
                              "
                            >
                              <v-icon> mdi-checkbox-marked-circle </v-icon>
                            </v-btn>

                            <v-btn
                              v-else
                              icon
                              small
                              depressed
                              color="error"
                              @click="
                                editConfirmItemsDialog = true;
                                editConfirmItem = item;
                              "
                            >
                              <v-badge
                                :content="
                                  item.received == false
                                    ? parseInt(item.quantity)
                                    : parseInt(item.quantity) - parseInt(item.received)
                                "
                                overlap
                                bordered
                                color="error"
                                x-small
                              >
                                <v-icon> mdi-cancel </v-icon>
                              </v-badge>
                            </v-btn>
                          </template>
                        </template>

                        <template v-slot:expanded-item="{ headers, item }">
                          <td class="pa-1" style="background: #fff"></td>
                          <td
                            :colspan="headers.length"
                            class="ma-n1 pa-0"
                            style="width: 200%; min-width: 200%"
                          >
                            <v-data-table
                              :headers="packageHeaders(item)"
                              :items="item.packages"
                              disable-filtering
                              disable-sort
                              hide-default-footer
                              disable-pagination
                              dense
                              no-data-text="Brak przypisanych paczek"
                              :items-per-page="-1"
                              style="padding-left: 5%; border-radius: 0"
                            >
                              <template v-slot:[`item.cardboard_boxes`]="props">
                                {{ props.item.cardboard_boxes ? 'TAK' : 'NIE' }}
                              </template>
                            </v-data-table>
                          </td>
                        </template>
                      </v-data-table>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>
                        Cena transportu (brutto):
                        <v-btn v-if="isAdmin" @click="activeMoneyDialog()" icon>
                          <v-icon> mdi-currency-usd </v-icon>
                        </v-btn>
                      </v-list-item-title>
                      <v-list-item-subtitle> {{ transport.priceVat }} zł </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6">
                  <v-list-item v-if="transport.pickup_price || sender" two-line>
                    <v-list-item-content>
                      <v-list-item-title>
                        Cena za odbiór (brutto):
                        <v-btn v-if="isAdmin" @click="activeMoneyDialog()" icon>
                          <v-icon> mdi-currency-usd </v-icon>
                        </v-btn>
                      </v-list-item-title>
                      <v-list-item-subtitle> {{ transport.pickup_price }} zł </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>
                        Szacowana data dostawy:
                        <v-btn v-if="isAdmin" @click="editDelivery = !editDelivery" icon>
                          <v-icon> mdi-calendar </v-icon>
                        </v-btn>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{
                          transport.expected
                            ? `${transport.expected} ${
                                transport.extra_info.delivery.start
                                  ? transport.extra_info.delivery.start +
                                    '-' +
                                    transport.extra_info.delivery.end
                                  : ''
                              }`
                            : ''
                        }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6">
                  <v-list-item two-line v-if="transport.money_to_collect">
                    <v-list-item-content>
                      <v-list-item-title>
                        Kwota do pobrania przy dostawie:
                        <v-btn v-if="isAdmin" @click="activeMoneyDialog()" icon>
                          <v-icon> mdi-currency-usd </v-icon>
                        </v-btn>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ transport.money_to_collect }} zł
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>
                        List przewozowy:
                        <v-btn @click="downloadPDF('Waybill')" icon>
                          <v-icon> mdi-file </v-icon>
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6">
                  <v-list-item two-line v-if="isAdmin && sender">
                    <v-list-item-content>
                      <v-list-item-title>
                        List odbiorczy:
                        <v-btn @click="downloadPDF('Reception')" icon>
                          <v-icon> mdi-file </v-icon>
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6">
                  <v-list-item two-line v-if="isAdmin || transport.extra_info.driverInfo.phone">
                    <v-list-item-content>
                      <v-list-item-title>
                        Numer do kierowcy:
                        <v-btn v-if="isAdmin" @click="editDriverInfo = true" icon>
                          <v-icon> mdi-card-account-details </v-icon>
                        </v-btn>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ transport.extra_info.driverInfo.phone }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6">
                  <v-list-item
                    v-if="transport.payment_id || transport.pickup_payment_id || transport.info"
                    two-line
                  >
                    <v-list-item-content>
                      <v-list-item-title> Dodatkowe informacje: </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ transport.info }}

                        <br />

                        <div v-if="transport.payment_id">
                          Płatność
                          <router-link :to="'/finanse/platnosc/' + transport.payment_id">
                            Zobacz płatność
                          </router-link>
                        </div>

                        <div v-if="transport.pickup_payment_id">
                          Pobranie
                          <router-link :to="'/finanse/pobranie/' + transport.pickup_payment_id">
                            Zobacz pobranie
                          </router-link>
                        </div>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6">
                  <v-list-item two-line v-if="mediaIds.length">
                    <v-list-item-content>
                      <v-list-item-title>
                        Załączniki:
                        <v-btn
                          icon
                          v-for="(item, index) in mediaIds"
                          :key="index"
                          @click="downloadAttachment(item, index)"
                        >
                          <v-icon> mdi-file </v-icon>
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- summary old format -->
          <v-expansion-panel v-else>
            <v-expansion-panel-header>
              {{ transport.t_number }} - Podsumowanie
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row v-if="sender && receiver" align="start" justify="space-between">
                <v-col cols="12">
                  <div class="map" id="fullMap"></div>
                </v-col>
              </v-row>

              <v-row align="start" justify="space-between">
                <v-col cols="6">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title> Numer transportu: </v-list-item-title>
                      <v-list-item-subtitle> {{ transport.t_number }} </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line v-if="transport.extension">
                    <v-list-item-content>
                      <v-list-item-title> Numer własny klienta: </v-list-item-title>
                      <v-list-item-subtitle> {{ transport.extension }} </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title> Zawartość: </v-list-item-title>
                      <v-list-item-subtitle> {{ transport.content }} </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>
                        Paczki:
                        <v-btn v-if="isAdmin" @click="activeEditPackagesDialog()" icon>
                          <v-icon> mdi-package-variant </v-icon>
                        </v-btn>
                      </v-list-item-title>

                      <div>
                        <v-list-item-subtitle v-for="(item, index) in packages.other" :key="index">
                          <v-row class="mb-2">
                            <v-col cols="10" class="my-0 py-0">
                              {{ item.name }} ({{ item.category_name }})
                              <br />
                              {{ item.variant_name ? item.variant_name : '' }}
                              <br v-if="item.variant_name" />
                              ({{ item.color + ', ' }}{{ item.SKU ? item.SKU + ', ' : ''
                              }}{{ item.packages.length + ' p.' }})
                              <v-tooltip top max-width="600" color="rgba(0, 0, 0, 0.9)">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    class="ml-2 panel-header-flex-fix"
                                    small
                                  >
                                    mdi-information
                                  </v-icon>
                                </template>
                                <span v-for="(p, j) in item.packages" :key="j">
                                  {{ 'Paczka ' + (j + 1) + ':' }}
                                  <br />
                                  {{ p.width + p.dimensions_unit + ' x ' }}
                                  {{ p.height + p.dimensions_unit + ' x ' }}
                                  {{ p.depth + p.dimensions_unit + ', ' }}
                                  {{ p.weight + p.measure_unit }}
                                  <br />
                                  {{ p.price + ' zł' }}
                                  <br />
                                  <br v-if="item.packages.length - 1 != j" />
                                </span>
                              </v-tooltip>
                            </v-col>

                            <v-col
                              cols="2"
                              align="center"
                              justify="center"
                              class="my-0 py-0"
                              v-if="isAdmin"
                            >
                              <v-tooltip top v-if="!item.received">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    class="ma-2"
                                    color="red"
                                    v-bind="attrs"
                                    v-on="on"
                                    test
                                    icon
                                    @click="received(true, 'other', item, index)"
                                  >
                                    <v-icon dark> mdi-cancel </v-icon>
                                  </v-btn>
                                </template>
                                <span> Kliknij, aby oznaczyć przedmiot jako odebrany </span>
                              </v-tooltip>
                              <v-tooltip top v-else>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    class="ma-2"
                                    color="green"
                                    v-bind="attrs"
                                    v-on="on"
                                    test
                                    icon
                                    @click="received(false, 'other', item, index)"
                                  >
                                    <v-icon dark> mdi-checkbox-marked-circle </v-icon>
                                  </v-btn>
                                </template>
                                <span> Kliknij, aby oznaczyć przedmiot jako nieodebrany </span>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </v-list-item-subtitle>
                      </div>

                      <!-- <div v-else>
                        <div v-if="packages.hard.length" class="ml-2">
                          <v-list-item-subtitle class="text--primary">
                            Meble twarde:
                          </v-list-item-subtitle>
                          <v-list-item-subtitle v-for="(item, index) in packages.hard" :key="index">
                            <v-row>
                              <v-col cols="12" sm="10" class="my-0 py-0">
                                <v-row>
                                  <v-col cols="12" class="pb-0">
                                    {{ item.name }} ({{ item.color }})
                                  </v-col>
                                  <v-col cols="12">
                                    {{ item.volume }}m³, {{ item.weight }}kg, {{ item.number }}szt
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col
                                cols="12"
                                sm="2"
                                align="center"
                                justify="center"
                                v-if="isAdmin"
                              >
                                <v-tooltip top v-if="!item.received">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      class="ma-2"
                                      color="red"
                                      v-bind="attrs"
                                      v-on="on"
                                      test
                                      icon
                                      @click="received(true, 'hard', item, index)"
                                    >
                                      <v-icon dark>mdi-cancel</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Kliknij, aby oznaczyć przedmiot jako odebrany</span>
                                </v-tooltip>
                                <v-tooltip top v-else>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      class="ma-2"
                                      color="green"
                                      v-bind="attrs"
                                      v-on="on"
                                      test
                                      icon
                                      @click="received(false, 'hard', item, index)"
                                    >
                                      <v-icon dark>mdi-checkbox-marked-circle</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Kliknij, aby oznaczyć przedmiot jako nieodebrany</span>
                                </v-tooltip>
                              </v-col>
                            </v-row>
                          </v-list-item-subtitle>
                        </div>
                        <div v-if="packages.other.length" class="ml-2">
                          <v-list-item-subtitle class="text--primary">
                            Meble tapicerowane i inne:
                          </v-list-item-subtitle>
                          <v-list-item-subtitle
                            v-for="(item, index) in packages.other"
                            :key="index"
                          >
                            <v-row>
                              <v-col cols="12" sm="10" class="my-0 py-0">
                                <v-row>
                                  <v-col cols="12" class="pb-0">
                                    {{ item.name }} ({{ item.color }})
                                  </v-col>
                                  <v-col cols="12">
                                    {{ item.width }}cm x {{ item.height }}cm x {{ item.depth }}cm,
                                    {{ item.weight }}kg
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col
                                cols="12"
                                sm="2"
                                align="center"
                                justify="center"
                                v-if="isAdmin"
                              >
                                <v-tooltip top v-if="!item.received">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      class="ma-2"
                                      color="red"
                                      v-bind="attrs"
                                      v-on="on"
                                      test
                                      icon
                                      @click="received(true, 'other', item, index)"
                                    >
                                      <v-icon dark>mdi-cancel</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Kliknij, aby oznaczyć przedmiot jako odebrany</span>
                                </v-tooltip>
                                <v-tooltip top v-else>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      class="ma-2"
                                      color="green"
                                      v-bind="attrs"
                                      v-on="on"
                                      test
                                      icon
                                      @click="received(false, 'other', item, index)"
                                    >
                                      <v-icon dark> mdi-checkbox-marked-circle </v-icon>
                                    </v-btn>
                                  </template>
                                  <span> Kliknij, aby oznaczyć przedmiot jako nieodebrany </span>
                                </v-tooltip>
                              </v-col>
                            </v-row>
                          </v-list-item-subtitle>
                        </div>
                      </div> -->
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>
                        Cena transportu (brutto):
                        <v-btn v-if="isAdmin" @click="activeMoneyDialog()" icon>
                          <v-icon> mdi-currency-usd </v-icon>
                        </v-btn>
                      </v-list-item-title>
                      <v-list-item-subtitle> {{ transport.priceVat }} zł </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content v-if="transport.pickup_price || sender">
                      <v-list-item-title>
                        Cena za odbiór (brutto):
                        <v-btn v-if="isAdmin" @click="activeMoneyDialog()" icon>
                          <v-icon> mdi-currency-usd </v-icon>
                        </v-btn>
                      </v-list-item-title>
                      <v-list-item-subtitle> {{ transport.pickup_price }} zł </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    two-line
                    v-if="transport.payment_id || transport.pickup_payment_id || transport.info"
                  >
                    <v-list-item-content>
                      <v-list-item-title> Dodatkowe informacje: </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ transport.info }}
                        <div v-if="transport.payment_id">
                          Transport opłacony<br />
                          <router-link :to="'/finanse/platnosc/' + transport.payment_id">
                            Zobacz płatność
                          </router-link>
                        </div>
                        <div v-if="transport.pickup_payment_id" class="mt-3">
                          Pobranie opłacone<br />
                          <router-link :to="'/finanse/pobranie/' + transport.pickup_payment_id">
                            Zobacz pobranie
                          </router-link>
                        </div>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="6">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title> Dodano: </v-list-item-title>
                      <v-list-item-subtitle> {{ transport.created }} </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        <v-chip
                          v-if="isAdmin"
                          :color="getStatusColor(transport.status)"
                          @click="editStatus = !editStatus"
                          dark
                        >
                          {{ getStatusName(transport.status) }}
                        </v-chip>
                        <v-chip v-else :color="getStatusColor(transport.status)" dark>
                          {{ getStatusName(transport.status) }}
                        </v-chip>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title> Odległość: </v-list-item-title>
                      <v-list-item-subtitle> {{ route.length }} </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>
                        Szacowana data dostawy:
                        <v-btn v-if="isAdmin" @click="editDelivery = !editDelivery" icon>
                          <v-icon> mdi-calendar </v-icon>
                        </v-btn>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{
                          transport.expected
                            ? `${transport.expected} ${
                                transport.extra_info.delivery.start
                                  ? transport.extra_info.delivery.start +
                                    '-' +
                                    transport.extra_info.delivery.end
                                  : ''
                              }`
                            : ''
                        }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line v-if="transport.money_to_collect">
                    <v-list-item-content>
                      <v-list-item-title>
                        Kwota do pobrania przy dostawie:
                        <v-btn v-if="isAdmin" @click="activeMoneyDialog()" icon>
                          <v-icon> mdi-currency-usd </v-icon>
                        </v-btn>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ transport.money_to_collect }} zł
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>
                        List przewozowy:
                        <v-btn @click="downloadPDF('Waybill')" icon>
                          <v-icon> mdi-file </v-icon>
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line v-if="isAdmin && sender">
                    <v-list-item-content>
                      <v-list-item-title>
                        List odbiorczy:
                        <v-btn @click="downloadPDF('Reception')" icon>
                          <v-icon> mdi-file </v-icon>
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line v-if="isAdmin || transport.extra_info.driverInfo.phone">
                    <v-list-item-content>
                      <v-list-item-title>
                        Numer do kierowcy:
                        <v-btn v-if="isAdmin" @click="editDriverInfo = true" icon>
                          <v-icon> mdi-card-account-details </v-icon>
                        </v-btn>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ transport.extra_info.driverInfo.phone }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line v-if="mediaIds.length">
                    <v-list-item-content>
                      <v-list-item-title>
                        Załączniki:
                        <v-btn
                          icon
                          v-for="(item, index) in mediaIds"
                          :key="index"
                          @click="downloadAttachment(item, index)"
                        >
                          <v-icon> mdi-file </v-icon>
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- pickup information -->
          <v-expansion-panel>
            <v-expansion-panel-header> Dane nadania </v-expansion-panel-header>
            <v-expansion-panel-content v-if="!sender">
              <v-row align="start" justify="space-between">
                <v-col cols="6">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title> Dostawa paczki na magazyn firmy </v-list-item-title>
                      <v-list-item-subtitle></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title> Termin dostarczenia towaru: </v-list-item-title>
                      <v-list-item-subtitle> {{ transport.pickup_date }} </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-expansion-panel-content>

            <v-expansion-panel-content v-else>
              <v-row align="start" justify="space-between">
                <v-col cols="6">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title> Nadawca: </v-list-item-title>
                      <v-list-item-subtitle> {{ sender.name }} </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title> Nr telefonu: </v-list-item-title>
                      <v-list-item-subtitle>
                        <a
                          :href="`tel:${sender.dialCode ? `+${sender.dialCode} ` : ''}${
                            sender.phone
                          }`"
                        >
                          {{ `${sender.dialCode ? `+${sender.dialCode} ` : ''}${sender.phone}` }}
                        </a>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line v-if="sender.phone_2">
                    <v-list-item-content>
                      <v-list-item-title> Nr telefonu (dodatkowy): </v-list-item-title>
                      <v-list-item-subtitle>
                        <a
                          :href="`tel:${sender.dialCode_2 ? `+${sender.dialCode_2} ` : ''}${
                            sender.phone_2
                          }`"
                        >
                          {{
                            `${sender.dialCode_2 ? `+${sender.dialCode_2} ` : ''}${sender.phone_2}`
                          }}
                        </a>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title> Ulica: </v-list-item-title>
                      <v-list-item-subtitle> {{ sender.street }} </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title> Kod pocztowy: </v-list-item-title>
                      <v-list-item-subtitle> {{ sender.postcode }} </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title> Miejscowość: </v-list-item-title>
                      <v-list-item-subtitle> {{ sender.city }} </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line v-if="isAdmin">
                    <v-list-item-content>
                      <v-list-item-title>
                        List odbiorczy:
                        <v-icon @click="downloadPDF('Reception')"> mdi-file </v-icon>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="6">
                  <div id="pickupMap" class="map"></div>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- delivery information -->
          <v-expansion-panel>
            <v-expansion-panel-header> Dane dostawy </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row align="start" justify="space-between">
                <v-col cols="6">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title> Odbiorca: </v-list-item-title>
                      <v-list-item-subtitle> {{ receiver.name }} </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title> Nr telefonu: </v-list-item-title>
                      <v-list-item-subtitle>
                        <a
                          :href="`tel:${receiver.dialCode ? `+${receiver.dialCode} ` : ''}${
                            receiver.phone
                          }`"
                        >
                          {{
                            `${receiver.dialCode ? `+${receiver.dialCode} ` : ''}${receiver.phone}`
                          }}
                        </a>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line v-if="receiver.phone_2">
                    <v-list-item-content>
                      <v-list-item-title> Nr telefonu (dodatkowy): </v-list-item-title>
                      <v-list-item-subtitle>
                        <a
                          :href="`tel:${receiver.dialCode_2 ? `+${receiver.dialCode_2} ` : ''}${
                            receiver.phone_2
                          }`"
                        >
                          {{
                            `${receiver.dialCode_2 ? `+${receiver.dialCode_2} ` : ''}${
                              receiver.phone_2
                            }`
                          }}
                        </a>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title> Ulica: </v-list-item-title>
                      <v-list-item-subtitle> {{ receiver.street }} </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title> Kod pocztowy: </v-list-item-title>
                      <v-list-item-subtitle> {{ receiver.postcode }} </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title> Miejscowość: </v-list-item-title>
                      <v-list-item-subtitle> {{ receiver.city }} </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="6">
                  <div id="deliveryMap" class="map"></div>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- customer details -->
          <v-expansion-panel>
            <v-expansion-panel-header> Informacje o zamawiającym </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row align="start" justify="space-between">
                <v-col cols="6">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title> Zlecający: </v-list-item-title>
                      <v-list-item-subtitle> {{ user.name }} </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line v-if="user.NIP">
                    <v-list-item-content>
                      <v-list-item-title> NIP: </v-list-item-title>
                      <v-list-item-subtitle> {{ user.NIP }} </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line v-if="isAdmin">
                    <v-list-item-content>
                      <v-list-item-title> Użytkownik: </v-list-item-title>
                      <v-list-item-subtitle v-if="user.active">
                        <router-link :to="'/uzytkownicy/' + transport.user_id">
                          Zobacz użytkownika
                        </router-link>
                      </v-list-item-subtitle>

                      <v-list-item-subtitle v-else> [Usunięty] </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="6">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title> Miejscowość: </v-list-item-title>
                      <v-list-item-subtitle> {{ user.city }} </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title> Kod pocztowy: </v-list-item-title>
                      <v-list-item-subtitle> {{ user.postcode }} </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line v-if="user.street">
                    <v-list-item-content>
                      <v-list-item-title> Ulica: </v-list-item-title>
                      <v-list-item-subtitle> {{ user.street }} </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>

              <p class="mb-0" v-if="invoice_data">
                <br />
                Dane do faktury:
              </p>

              <v-row align="start" justify="space-between" v-if="invoice_data">
                <v-col cols="6">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title> Nazwa: </v-list-item-title>
                      <v-list-item-subtitle> {{ invoice_data.name }} </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title> NIP: </v-list-item-title>
                      <v-list-item-subtitle> {{ invoice_data.NIP }} </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="6">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title> Miejscowość: </v-list-item-title>
                      <v-list-item-subtitle> {{ invoice_data.city }} </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title> Kod pocztowy: </v-list-item-title>
                      <v-list-item-subtitle> {{ invoice_data.postcode }} </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title> Ulica: </v-list-item-title>
                      <v-list-item-subtitle> {{ invoice_data.street }} </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- transport history -->
          <v-expansion-panel>
            <v-expansion-panel-header> Historia transportu </v-expansion-panel-header>
            <v-expansion-panel-content id="status_history">
              <v-row align="center" justify="center" no-gutters>
                <v-col cols="2" align="center" justify="center" class="px-2"> Data </v-col>

                <v-col cols="4" align="center" justify="center" class="px-2"> Status </v-col>

                <v-col cols="6" align="center" justify="center" class="px-2"> Treść </v-col>
                <v-divider class="mx-1" />
              </v-row>

              <v-row
                v-for="(item, index) in status_logs"
                :key="index"
                align="center"
                justify="center"
                no-gutters
              >
                <v-col cols="2" align="center" justify="center" class="px-2">
                  {{ dateToString(item.created) }}
                </v-col>

                <v-col cols="4" align="center" justify="center" class="px-2">
                  <v-row>
                    <v-col cols="1" class="py-0">
                      <v-divider vertical />
                    </v-col>

                    <v-col cols="10" align="center" justify="center">
                      <v-chip :color="getStatusColor(item.status)" dark>
                        {{ getStatusName(item.status) }}
                      </v-chip>
                    </v-col>

                    <v-col cols="1" class="py-0">
                      <v-divider vertical />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="6" class="px-2">
                  {{ item.message }}
                </v-col>

                <v-col
                  cols="2"
                  align="center"
                  justify="center"
                  class="px-2"
                  v-if="status_logs.length - 1 != index"
                >
                  <!-- empty -->
                </v-col>

                <v-col
                  cols="4"
                  align="center"
                  justify="center"
                  class="px-2"
                  v-if="status_logs.length - 1 != index"
                >
                  <v-row>
                    <v-col cols="1" class="py-0">
                      <v-divider vertical />
                    </v-col>

                    <v-col cols="10" align="center" justify="center" class="py-0">
                      <v-icon large> mdi-ray-start-arrow mdi-rotate-90 </v-icon>
                    </v-col>

                    <v-col cols="1" class="py-0">
                      <v-divider vertical />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="6" class="px-2" v-if="status_logs.length - 1 != index">
                  <!-- empty -->
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <!-- bottom nav -->
    <v-bottom-navigation>
      <v-btn
        v-if="
          isAdmin &&
          (transport.status >= 3 || transport.status == 1 || (transport.status == 0 && !sender))
        "
        :to="{ name: 'DeliveryList' }"
        value="recent"
      >
        <span> Powrót do dostaw </span>
        <v-icon> mdi-history </v-icon>
      </v-btn>

      <v-btn v-else-if="isAdmin" :to="{ name: 'PickUpList' }" value="recent">
        <span> Powrót do odbiorów </span>
        <v-icon> mdi-history </v-icon>
      </v-btn>

      <v-btn v-else :to="{ name: 'allTransport' }" value="recent">
        <span> Powrót do listy </span>
        <v-icon> mdi-history </v-icon>
      </v-btn>

      <v-btn
        v-if="isAdmin"
        value="favorites"
        @click="sendMsg = !sendMsg"
        class="btn--active-prevent"
      >
        <span> Wyślij wiadomość </span>
        <v-icon> mdi-email </v-icon>
      </v-btn>
      <v-btn
        v-else-if="transport.status < 4"
        value="favorites"
        @click="sendMsg = !sendMsg"
        class="btn--active-prevent"
      >
        <span> Zgłoś uwagi </span>
        <v-icon> mdi-email </v-icon>
      </v-btn>
      <v-btn v-else value="favorites" @click="sendMsg = !sendMsg" class="btn--active-prevent">
        <span> Wyślij wiadomość </span>
        <v-icon> mdi-email </v-icon>
      </v-btn>

      <v-btn
        v-if="!isAdmin"
        value="nearby"
        @click="editStatus = !editStatus"
        class="btn--active-prevent"
      >
        <span> Zgłoś problem </span>
        <v-icon> mdi-alert-octagon </v-icon>
      </v-btn>

      <v-btn
        v-if="isAdmin"
        @click="editStatus = !editStatus"
        :retain-focus-on-click="false"
        class="btn--active-prevent"
      >
        <span> Zmień status </span>
        <v-icon> mdi-auto-fix </v-icon>
      </v-btn>

      <v-btn v-if="isAdmin" @click="editData = !editData" class="btn--active-prevent">
        <span> Edytuj dane </span>
        <v-icon> mdi-circle-edit-outline </v-icon>
      </v-btn>
    </v-bottom-navigation>

    <!-- ticket dialog -->
    <v-overlay v-if="!isAdmin" :value="editStatus" :dark="false" :light="true">
      <v-toolbar flat color="black" :dark="true">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="headline ma-0 pa-0"> Twoje zgłoszenie </v-list-item-title>
            <v-list-item-subtitle class="mt-n4">
              Tutaj możesz zgłosić reklamację
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-spacer />
        <v-btn @click="editStatus = false" icon>
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-toolbar>
      <Ticket :transport="transport.tid"></Ticket>
    </v-overlay>

    <!-- status dialog -->
    <v-dialog
      v-if="isAdmin && editStatus"
      v-model="editStatus"
      :dark="false"
      :light="true"
      max-width="600px"
    >
      <v-toolbar flat color="black" :dark="true">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="headline ma-0 pa-0"> Zmień status </v-list-item-title>
            <v-list-item-subtitle class="mt-n4">
              Tutaj możesz zmienić status transportu
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-spacer />
        <v-btn @click="editStatus = false" icon>
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-toolbar>
      <TransportStatus
        :transport="[{ transport_id: transport.tid, status: transport.status }]"
        @updateStatus="updateStatus($event)"
      ></TransportStatus>
    </v-dialog>

    <!-- edit transport dialog -->
    <v-dialog v-if="isAdmin" v-model="editData" :dark="false" :light="true" max-width="600px">
      <v-toolbar flat color="black" :dark="true">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="headline ma-0 pa-0"> Edycja danych </v-list-item-title>
            <v-list-item-subtitle class="mt-n4">
              Tutaj możesz edytować wszystkie dane ze zlecenia
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-spacer />
        <v-btn @click="editData = false" icon>
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-toolbar>
      <TransportEditForm
        v-if="editData"
        :transport="transport"
        :receiver="receiver"
        :sender="sender"
      ></TransportEditForm>
    </v-dialog>

    <!-- message dialog -->
    <v-dialog :value="sendMsg" :dark="false" :light="true" max-width="600px">
      <v-toolbar color="black" :dark="true">
        <v-spacer />
        <v-btn @click="sendMsg = false" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <MessageDialog
        :send_to="transport.user_id"
        :title="subject"
        :transport_id="transport.tid"
      ></MessageDialog>
    </v-dialog>

    <!-- new delivery date-picker -->
    <DatePicker
      v-if="isAdmin"
      v-model="editDelivery"
      title="Edytuj date dostawy"
      :selected="deliveryDate"
      :dateOnly="false"
      @save="newEditDate($event, 'delivery')"
    ></DatePicker>

    <!-- alert dialog -->
    <v-dialog v-model="alert" max-width="510px" persistent>
      <v-card>
        <v-toolbar flat color="error" :dark="true">
          <v-row no-gutter class="justify-center">
            <v-toolbar-title>UWAGA</v-toolbar-title>
          </v-row>
        </v-toolbar>
        <v-divider dark class="my-1" />
        <v-card-text>
          <div class="text--primary">
            {{ message }}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="alert = false"> Rozumiem </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- edit packages dialog -->
    <v-dialog v-model="editPackages" v-if="isAdmin" :dark="false" :light="true" max-width="800px">
      <TransportEditPackages
        :active="editPackages"
        :orderStatus="transport.status"
        :oldHardPackages="packages.hard"
        :oldOtherPackages="packages.other"
        @disableDialog="disableDialog"
        @packagesUpdated="packagesUpdated"
      ></TransportEditPackages>
    </v-dialog>

    <!-- edit price dialog -->
    <v-dialog max-width="600px" v-model="editMoney" v-if="isAdmin">
      <v-card class="mx-auto">
        <v-toolbar flat color="black" :dark="true">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="headline ma-0 pa-0">Edycja cen</v-list-item-title>
              <v-list-item-subtitle class="mt-n4">
                Możesz ręcznie edytować ceny
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-spacer />
        </v-toolbar>
        <v-card-text class="pb-0">
          <v-form ref="moneyForm" v-model="validMoney" lazy="true" class="mt-4">
            <v-row align="center" justify="center" class="mx-0 pt-4">
              <v-col cols="12" sm="12" class="my-0 py-0">
                <v-text-field
                  v-if="newFormat"
                  v-model="editMoneyData.priceVat"
                  :rules="floatPriceRule"
                  label="Cena dostarczenia (brutto)"
                  name="price"
                  type="number"
                  suffix="zł"
                  min="0"
                />
              </v-col>

              <v-col
                cols="12"
                sm="12"
                class="my-0 py-0"
                v-if="editMoneyData.pickup_price || sender"
              >
                <v-text-field
                  v-model="editMoneyData.pickup_price"
                  label="Cena odbioru (brutto)"
                  name="pickup"
                  type="number"
                  suffix="zł"
                  min="0"
                />
              </v-col>

              <v-col cols="12" sm="12" class="my-0 py-0">
                <v-row no-gutters>
                  <v-checkbox v-model="editMoneyData.payed_in_advance" label="Pobranie" />
                  <v-text-field
                    v-if="editMoneyData.payed_in_advance"
                    v-model="editMoneyData.money_to_collect"
                    :rules="floatPriceRule"
                    label="Kwota pobrania"
                    name="collect"
                    type="number"
                    suffix="zł"
                    min="0"
                    class="pl-3"
                  />
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions class="pa-0">
          <v-col cols="12" sm="3">
            <v-btn
              block
              @click="editMoney = !editMoney"
              color="white"
              class="grey--text text--darken-4"
              outlined
              :x-large="true"
            >
              Anuluj
            </v-btn>
          </v-col>
          <v-spacer />
          <v-col cols="12" sm="3">
            <v-btn
              block
              @click="changeMoney()"
              :disabled="!validMoney"
              color="amber accent-3"
              class="grey--text text--darken-4"
              :x-large="true"
              v-tilt
            >
              Zmień
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- add item component -->
    <AddEditItemsAdmin
      v-if="addEditDialog"
      title="Edytuj przedmioty"
      :items="packages.other"
      max_width="1000px"
      @deactive="
        addEditDialog = false;
        resetSnackbar();
      "
      @message="messageM"
      @edit="editItems"
    ></AddEditItemsAdmin>

    <!-- modal progress circular -->
    <v-dialog v-model="loadingProgress" fullscreen>
      <v-container fluid fill-height style="background-color: rgba(255, 255, 255, 0.5)">
        <v-layout justify-center align-center>
          <v-progress-circular indeterminate color="amber"> </v-progress-circular>
        </v-layout>
      </v-container>
    </v-dialog>

    <!-- snackbar -->
    <v-snackbar v-model="snackbar" :color="snackbarType" :multi-line="true" :timeout="timeout">
      {{ snackbarMessageList[0] ? snackbarMessageList[0].content : '' }}

      <v-btn :color="snackbarButtonColor" text @click="snackbar = false"> Zamknij </v-btn>
    </v-snackbar>

    <!-- NEW snackbar -->
    <InfoSnackBar v-model="info"></InfoSnackBar>

    <!-- edit driver info -->
    <EditDriverInfoDialog
      v-model="editDriverInfo"
      :info="transport.extra_info.driverInfo"
      @save="saveDriverInfo"
    ></EditDriverInfoDialog>

    <!-- edit items dialog (new format) -->
    <EditTransportItemsDialog
      v-model="editItemsDialog"
      :items="transport.params"
      @submit="editTransportPackages"
      @info="info = $event"
    ></EditTransportItemsDialog>

    <!-- confirm items (new format) -->
    <EditTransportConfirmItemsDialog
      v-if="editConfirmItemsDialog"
      v-model="editConfirmItemsDialog"
      :item="editConfirmItem"
      @submit="confirmItems"
    ></EditTransportConfirmItemsDialog>
  </v-container>
</template>
<script src="https://api.mapbox.com/mapbox-gl-js/v0.42.0/mapbox-gl.js"></script>
<script>
  // <link href="https://api.mapbox.com/mapbox-gl-js/v0.42.0/mapbox-gl.css" rel="stylesheet" />
  import store from '../../store';

  import tt from '@tomtom-international/web-sdk-maps';
  import ts from '@tomtom-international/web-sdk-services';

  import Ticket from '../../components/Ticket.vue';
  import TransportStatus from '../../components/TransportStatus.vue';
  import TransportEditForm from '../../components/TransportEditForm.vue';
  import TransportEditPackages from '../../components/TransportEditPackages.vue';
  import MessageDialog from '../../components/MessageDialog.vue';
  import AddEditItemsAdmin from '../../components/AddEditItemsAdmin.vue';
  import EditDriverInfoDialog from '../../components/EditDriverInfoDialog.vue';
  import EditTransportItemsDialog from '../../components/EditTransportItemsDialog.vue';
  import EditTransportConfirmItemsDialog from '../../components/EditTransportConfirmItemsDialog.vue';
  import InfoSnackBar from '../../components/InfoSnackBar.vue';

  import DatePicker from '../../components/DatePicker.vue';

  import { mdiPackageVariant, mdiPin, mdiContacts, mdiFilePdf, mdiCoin } from '@mdi/js';

  import axios from 'axios';
  import mime from 'mime-types';

  export default {
    name: 'SingleTransport',
    components: {
      Ticket,
      TransportStatus,
      TransportEditForm,
      TransportEditPackages,
      MessageDialog,
      AddEditItemsAdmin,
      EditDriverInfoDialog,

      DatePicker,
      EditTransportItemsDialog,
      EditTransportConfirmItemsDialog,
      InfoSnackBar,
    },
    data: () => ({
      icons: {
        mdiPackageVariant,
        mdiPin,
        mdiContacts,
        mdiFilePdf,
        mdiCoin,
      },
      floatPriceRule: [
        v =>
          /^([1-9]\d*(\.|,)\d{1,2}|0(\.|,)\d{1,2}|[1-9]\d*|0)$/.test(v) ||
          'Liczba ma niepoprawny format lub jest ujemna',
      ],
      panels: [0, 1, 2],
      packages: {
        hard: [],
        other: [],
      },
      transport: { params: [], extra_info: { driverInfo: {}, delivery: {} } },
      receiver: {},
      sender: {},
      user: {},
      invoice_data: {},
      mediaIds: [],
      status_logs: [],
      search: null,
      route: {
        length: null,
        time: null,
      },
      maps: {
        pickup: null,
        delivery: null,
        full: null,
      },
      markers: {
        pickup: null,
        delivery: null,
      },
      editStatus: false,
      editData: false,
      editDate: {
        active: false,
        date: null,
        defaultDate: null,
      },
      sendMsg: false,
      subject: null,
      isAdmin: store.getters.isAdmin,
      message: null,
      alert: false,

      editMoney: false,
      editMoneyData: {
        priceVat: 0.0,
        pickup_price: 0.0,
        payed_in_advance: false,
        money_to_collect: 0.0,
      },
      validMoney: false,

      editPackages: false,

      snackbar: false,
      snackbarMessageList: [],

      loadingProgress: false,
      newFormat: false,
      addEditDialog: false,

      editDelivery: false,
      deliveryDate: null,
      deliveryDateCopy: null,
      editPickup: false,
      pickupDate: null,
      confirm_date: false,
      mail: false,

      editDriverInfo: false,

      preventBtn: false,

      editItemsDialog: false,
      editConfirmItemsDialog: false,
      editConfirmItem: null,
      info: null,
    }),
    computed: {
      timeout: function () {
        return this.snackbarMessageList[0] && this.snackbarMessageList[0].timeout
          ? this.snackbarMessageList[0].timeout
          : 6000;
      },
      snackbarType: function () {
        return this.snackbarMessageList[0] && this.snackbarMessageList[0].type == 'error'
          ? 'red'
          : '';
      },
      snackbarButtonColor: function () {
        return this.snackbarMessageList[0] && this.snackbarMessageList[0].type == 'error'
          ? ''
          : 'amber';
      },
      headers: function () {
        const h = [
          // {
          //   text: 'Typ',
          //   value: 'category_id',
          //   align: 'center',
          //   width: '98px',
          // },
          {
            text: 'Typ',
            value: 'item_type',
            align: 'center',
            width: '112px',
          },
          {
            text: 'Ilość',
            value: 'quantity',
            align: 'center',
            width: '92px',
          },
          {
            text: 'Nazwa / Wariant / SKU',
            value: 'name',
            align: 'center',
            width: '70%',
          },
          {
            text: 'Wybarwienie',
            value: 'color',
            align: 'center',
            width: '30%',
          },
          {
            text: 'J. ilość elementów',
            value: 'packages.length',
            align: 'center',
            width: '92px',
          },
          {
            text: '',
            value: 'data-table-expand',
            width: '100px',
          },
        ];

        if (!this.isAdmin) h.find(elem => elem.value === 'data-table-expand').width = '50px';

        return this.isAdmin ? h : h.slice(1);
      },
    },
    watch: {
      'snackbarMessageList.length': function (val) {
        if (val) {
          let that = this;
          setTimeout(function () {
            that.snackbar = true;
          }, 100);
        }
      },
      snackbar: function (val) {
        if (!val) {
          this.snackbarMessageList.shift();
        }
      },
    },
    methods: {
      newEditDate(obj, type) {
        this.loadingProgress = true;
        axios({
          url: this.$store.state.url + '/api/transport/modify/date',
          data: {
            token: this.$store.state.token,
            id: this.$route.params.id,
            date: new Date(obj.date),
            type: type,
            mail: obj.checkboxes.sendMail,
            confirm_date: obj.checkboxes.confirmDate,
            notification: obj.checkboxes.sendNotification,
            info: {
              delivery: {
                start: obj.timeStart,
                end: obj.timeEnd,
              },
            },
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status === 200) {
              const dateObj = obj.date instanceof Date ? obj.date : new Date(obj.date);
              const year = '' + dateObj.getFullYear(),
                month = '' + (dateObj.getMonth() + 1),
                day = '' + dateObj.getDate();

              if (type == 'delivery') {
                this.transport.expected = [
                  day.length < 2 ? '0' + day : day,
                  month.length < 2 ? '0' + month : month,
                  year,
                ].join('.');
                this.transport.date_expected_confirm = obj.checkboxes.confirmDate;
                this.editDelivery = false;
                this.deliveryDate = [
                  year,
                  month.length < 2 ? '0' + month : month,
                  day.length < 2 ? '0' + day : day,
                ].join('-');
                this.deliveryDateCopy = [
                  year,
                  month.length < 2 ? '0' + month : month,
                  day.length < 2 ? '0' + day : day,
                ].join('-');

                this.transport.extra_info.delivery = {
                  start: obj.timeStart,
                  end: obj.timeEnd,
                };
              } else {
                this.transport.pickup_date = [
                  day.length < 2 ? '0' + day : day,
                  month.length < 2 ? '0' + month : month,
                  year,
                ].join('.');
                this.transport.pickup_date_confirm = obj.checkboxes.confirmDate;
                this.editPickup = false;
                this.pickupDate = [
                  year,
                  month.length < 2 ? '0' + month : month,
                  day.length < 2 ? '0' + day : day,
                ].join('-');
                this.pickupDateCopy = [
                  year,
                  month.length < 2 ? '0' + month : month,
                  day.length < 2 ? '0' + day : day,
                ].join('-');
              }

              this.messageM(
                {
                  content: resp.data.message,
                  type: 'success',
                },
                false
              );
            } else {
              this.messageM(
                {
                  content: resp.data.message,
                  type: 'error',
                },
                true
              );
            }

            this.loadingProgress = false;
          })
          .catch(err => {
            this.messageM(
              {
                content: 'Nieoczekiwany błąd. Odśwież stronę i spróbuj ponownie',
                type: 'error',
              },
              true
            );
            this.loadingProgress = false;
          });
      },
      saveDriverInfo(driverInfo) {
        this.loadingProgress = true;
        axios({
          url: this.$store.state.url + '/api/transport/setExtraInfo',
          data: {
            token: this.$store.state.token,
            id: this.transport.tid,
            info: {
              driverInfo: driverInfo || {},
            },
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status === 200) {
              this.transport.extra_info.driverInfo.phone = driverInfo.phone;
              this.transport.extra_info.driverInfo.name = driverInfo.name;

              this.editDriverInfo = false;
              this.messageM(
                {
                  content: resp.data.message,
                },
                false
              );
            } else {
              if (resp.data.message)
                this.messageM(
                  {
                    content: resp.data.message,
                    type: 'error',
                  },
                  true
                );
              else
                this.messageM(
                  {
                    content: 'Nieoczekiwany błąd. Spróbuj ponownie',
                    type: 'error',
                  },
                  true
                );
            }

            this.loadingProgress = false;
          })
          .catch(() => {
            this.messageM(
              {
                content: 'Nieoczekiwany błąd. Odśwież stronę i spróbuj ponownie',
                type: 'error',
              },
              true
            );

            this.loadingProgress = false;
          });
      },
      dateToString(date) {
        if (typeof date === 'string' || date instanceof String) date = new Date(date);

        return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1)
          .toString()
          .padStart(2, '0')}-${date.getFullYear().toString().padStart(4, '0')} ${date
          .getHours()
          .toString()
          .padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date
          .getSeconds()
          .toString()
          .padStart(2, '0')}`;
      },
      updateStatus(status) {
        this.transport.status = status;
        this.editStatus = false;

        // if(status > 2) {
        //   if(this.packages.other.length) {
        //     for(let index = 0; index < this.packages.other.length; index++) {
        //       this.packages.other[index].received = true;
        //     }
        //   }

        //   if(this.packages.hard.length) {
        //     for(let index = 0; index < this.packages.hard.length; index++) {
        //       this.packages.hard[index].received = true;
        //     }
        //   }
        // }
        this.$router.go();
      },
      getMapStyle: function () {
        let d = new Date();
        let s = '';
        if (d.getHours() < 7 || d.getHours() > 20) {
          s = 'tomtom://vector/1/basic-night';
        } else {
          s = 'tomtom://vector/1/basic-main';
        }
        return s;
      },
      onRouteLoad: function (routeGeoJson) {
        let r = routeGeoJson[0][0].routeSummary;

        if (r.lengthInMeters < 1000) this.route.length = `${r.lengthInMeters} m`;
        if (r.lengthInMeters >= 1000)
          this.route.length = `${(r.lengthInMeters / 1000).toPrecision(3)} km`;

        if (r.travelTimeInSeconds < 60) this.route.time = `${r.travelTimeInSeconds} s`;
        if (r.travelTimeInSeconds >= 60 && r.travelTimeInSeconds < 60 * 60)
          this.route.time = `${(r.travelTimeInSeconds / 60).toPrecision(3)} min`;
        if (r.travelTimeInSeconds >= 60 * 60)
          this.route.time = `${(r.travelTimeInSeconds / 60 / 60).toPrecision(3)} h`;
      },
      getStatusColor: function (status) {
        let r;
        // STATUS:
        switch (status) {
          case 0:
            r = 'red';
            break;
          case 1:
            r = 'black';
            break;
          case 2:
            r = 'grey';
            break;
          case 3:
            r = 'teal';
            break;
          case 4:
            r = 'green';
            break;
          case 5:
            r = 'amber';
            break;
          case 6:
            r = 'blue';
            break;
          case 7:
            r = 'indigo';
            break;
          case 8:
            r = 'silver';
            break;
          case 9:
            r = 'red darken-4';
            break;
        }
        return r;
      },
      getStatusName: function (status) {
        let r;
        // STATUS:
        switch (status) {
          case 0:
            r = 'Wymagana akceptacja';
            break;
          case 1:
            r = 'Oczekuje na magazyn';
            break;
          case 2:
            r = 'Do odbioru u klienta';
            break;
          case 3:
            r = 'Oczekuje na wysyłkę';
            break;
          case 4:
            r = 'Termin uzgodniony z odbiorcą';
            break;
          case 5:
            r = 'Wysłano';
            break;
          case 6:
            r = 'Dostarczono';
            break;
          case 7:
            r = 'Oczekuje na pobranie';
            break;
          case 8:
            r = 'Zarchiwizowano';
            break;
          case 9:
            r = 'Anulowano';
            break;
        }
        return r;
      },
      downloadPDF: function (type) {
        axios({
          url: this.$store.state.url + '/api/transport/get_pdf',
          data: {
            token: this.$store.state.token,
            id: this.transport.tid,
            type: type,
          },
          method: 'POST',
          responseType: 'blob',
        })
          .then(resp => {
            if (resp.status === 200) {
              var fileURL = window.URL.createObjectURL(new Blob([resp.data]));
              var fileLink = document.createElement('a');

              fileLink.href = fileURL;
              fileLink.setAttribute('download', `${this.transport.t_number}_${type}.pdf`);
              document.body.appendChild(fileLink);

              fileLink.click();
              fileLink.parentNode.removeChild(fileLink);
            } else {
              if (resp.data.message) alert(resp.data.message);
              else alert('Niepowiodło się');
            }
          })
          .catch(() => {
            alert('Niepowiodło się');
          });
      },
      downloadAttachment: function (id, index) {
        axios({
          url: this.$store.state.url + '/api/media/file',
          data: {
            token: this.$store.state.token,
            transport_id: this.transport.tid,
            file_id: id,
          },
          method: 'POST',
          responseType: 'blob',
        })
          .then(resp => {
            if (resp.status === 200) {
              var fileName = `${this.transport.t_number}_${index}`;
              var fileExt = mime.extension(resp.headers['content-type'])
                ? mime.extension(resp.headers['content-type'])
                : 'txt';
              var fileURL = window.URL.createObjectURL(new Blob([resp.data]));
              var fileLink = document.createElement('a');

              fileLink.href = fileURL;
              fileLink.setAttribute('download', `${fileName}.${fileExt}`);
              document.body.appendChild(fileLink);

              fileLink.click();
              fileLink.parentNode.removeChild(fileLink);
            } else {
              if (resp.data.message) alert(resp.data.message);
              else alert('Niepowiodło się');
            }
          })
          .catch(() => {
            alert('Niepowiodło się');
          });
      },
      changeDate: function (type = 'delivery') {
        this.preventBtn = true;

        let split = this.transport.pickup_date.split('.');
        if (new Date(this.editDate.date) < new Date(split[2] + '-' + split[1] + '-' + split[0])) {
          this.message = 'Szacowany czas dostawy nie może być przed datą nadania';
          this.preventBtn = false;
          return (document.getElementsByClassName('v-dialog v-dialog--active')[0].scrollTop = 0);
        }

        axios({
          url: this.$store.state.url + '/api/transport/modify/date',
          data: {
            token: this.$store.state.token,
            id: this.$route.params.id,
            date: type == 'delivery' ? new Date(this.deliveryDate) : new Date(this.pickupDate),
            type: type,
            mail: this.mail,
            confirm_date: this.confirm_date,
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status === 200) {
              // date to string
              let date = type == 'delivery' ? new Date(this.deliveryDate) : new Date(this.pickupDate);
              let year = date.getFullYear();
              let month = date.getMonth() + 1;
              let dt = date.getDate();

              if (dt < 10) dt = '0' + dt;
              if (month < 10) month = '0' + month;

              if (type == 'delivery') {
                this.transport.expected = dt + '.' + month + '.' + year;
                this.transport.date_expected_confirm = this.confirm_date;
                this.editDelivery = false;
                this.deliveryDate = `${year}-${month}-${dt}`;
                this.deliveryDateCopy = `${year}-${month}-${dt}`;
              } else {
                this.transport.pickup_date = dt + '.' + month + '.' + year;
                this.transport.pickup_date_confirm = this.confirm_date;
                this.editPickup = false;
                this.pickupDate = `${year}-${month}-${dt}`;
                this.pickupDateCopy = `${year}-${month}-${dt}`;
              }

              this.preventBtn = false;
              this.message = null;
              this.mail = false;
              this.confirm_date = false;
            } else {
              this.message = resp.data.message;
              let that = this;
              setTimeout(() => {
                that.preventBtn = false;
              }, 200);
            }
          })
          .catch(() => {
            let that = this;
            setTimeout(() => {
              that.preventBtn = false;
            }, 200);
          });
      },
      received: function (flag, type, item, index) {
        let tmp = index;
        if (type !== 'hard') {
          tmp += this.packages.hard.length;
        }

        axios({
          url: this.$store.state.url + '/api/transport/items/status',
          data: {
            token: this.$store.state.token,
            id: this.$route.params.id,
            flag: flag,
            type: type,
            item: item,
            index: tmp,
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status === 200) {
              if (type === 'hard') {
                let tmp = this.packages.hard[index];
                tmp.received = flag;
                this.$set(this.packages.hard, index, tmp);
              } else {
                let tmp = this.packages.other[index];
                tmp.received = flag;
                this.$set(this.packages.other, index, tmp);
              }
            } else {
              this.message = resp.data.message;
              this.alert = true;
            }
          })
          .catch(err => {
            this.message = err;
            this.alert = true;
          });
      },
      activeMoneyDialog: function () {
        this.editMoneyData = {
          priceVat: this.transport.priceVat,
          pickup_price: this.transport.pickup_price,
          payed_in_advance: this.transport.inAdvance,
          money_to_collect: this.transport.money_to_collect,
        };

        this.editMoney = true;
      },
      changeMoney: function () {
        axios({
          url: this.$store.state.url + '/api/transport/modify/money',
          data: {
            token: this.$store.state.token,
            id: this.$route.params.id,
            priceVat: this.editMoneyData.priceVat,
            pickup_price: this.editMoneyData.pickup_price,
            payed_in_advance: this.editMoneyData.payed_in_advance ? 1 : 0,
            money_to_collect: this.editMoneyData.money_to_collect,
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status === 200) {
              this.transport.priceVat = this.editMoneyData.priceVat;
              this.transport.pickup_price = this.editMoneyData.pickup_price;
              this.transport.inAdvance = this.editMoneyData.payed_in_advance;
              this.transport.money_to_collect = this.editMoneyData.payed_in_advance
                ? this.editMoneyData.money_to_collect
                : 0.0;
              this.editMoney = !this.editMoney;
            } else {
              this.editMoney = !this.editMoney;
              this.message = resp.data.message;
              this.alert = true;
            }
          })
          .catch(err => {
            this.editMoney = !this.editMoney;
            this.message = err;
            this.alert = true;
          });
      },
      disableDialog: function () {
        let that = this;
        setTimeout(function () {
          that.editPackages = false;
        }, 300);
        this.message = null;
      },
      packagesUpdated: function (hardPackages, otherPackages) {
        this.packages.hard = hardPackages;
        this.packages.other = otherPackages;
        let tmp = '';
        this.packages.hard.forEach(element => {
          tmp += ', ' + element.name + '(' + element.color + ')';
        });
        this.packages.other.forEach(element => {
          tmp += ', ' + element.name + '(' + element.color + ')';
        });
        this.transport.content = tmp.slice(2);

        axios({
          url: this.$store.state.url + '/api/transport/modify/items',
          data: {
            token: this.$store.state.token,
            id: this.$route.params.id,
            packages: JSON.stringify([].concat(this.packages.hard, this.packages.other)),
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status === 200) {
              axios({
                url: this.$store.state.url + '/api/transport/get',
                data: {
                  token: this.$store.state.token,
                  type: 'single',
                  id: this.$route.params.id,
                },
                method: 'POST',
              })
                .then(resp => {
                  if (resp.data.status === 200) {
                    let t = resp.data.transports[0];
                    this.transport.price = t.price;
                    this.transport.priceVat = t.priceVat;
                    this.transport.params = t.packages ? JSON.parse(t.packages) : null;
                  } else {
                    this.message = `Napotkano niespodziewany błąd. Strona za chwile zostanie odswieżona.${
                      resp.data.message ? ' (' + resp.data.message + ')' : ''
                    }`;
                    this.alert = true;
                    setTimeout(function () {
                      window.location.reload();
                    }, 3000);
                  }
                })
                .catch(err => {
                  this.message = `Napotkano niespodziewany bląd. Strona za chwile zostanie odswieżona.${
                    resp.data.message ? ' (' + resp.data.message + ')' : ''
                  }`;
                  this.alert = true;
                  setTimeout(function () {
                    window.location.reload();
                  }, 3000);
                });
            } else {
              this.message = `Napotkano niespodziewany błąd. Strona za chwile zostanie odswieżona.${
                resp.data.message ? ' (' + resp.data.message + ')' : ''
              }`;
              this.alert = true;
              setTimeout(function () {
                window.location.reload();
              }, 3000);
            }
          })
          .catch(err => {
            this.message = `Napotkano niespodziewany błąd. Strona za chwile zostanie odswieżona.${
              resp.data.message ? ' (' + resp.data.message + ')' : ''
            }`;
            this.alert = true;
            setTimeout(function () {
              window.location.reload();
            }, 3000);
          });
      },
      activeEditPackagesDialog: function () {
        this.addEditDialog = true;
      },
      editItems: function (items) {
        this.loadingProgress = true;
        let tmp = items.map(item => {
          return {
            item_id: item.item_id ? item.item_id : null,
            name: item.name,
            category_name: item.category_name,
            item_variant_id: item.item_variant_id ? item.item_variant_id : null,
            variant_name: item.variant_name ? item.variant_name : '',
            color: item.color,
            SKU: item.SKU ? item.SKU : '',
            packages: item.packages.map(pck => {
              return {
                dimensions_unit: pck.dimensions_unit,
                width: pck.width,
                height: pck.height,
                depth: pck.depth,
                measure_unit: pck.measure_unit,
                weight: pck.weight,
                price: pck.price,
              };
            }),
            received: item.received ? item.received : false,
          };
        });

        axios({
          url: this.$store.state.url + '/api/transport/modify/itemsNew',
          data: {
            token: this.$store.state.token,
            id: this.$route.params.id,
            packages: JSON.stringify(tmp),
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status === 200) {
              axios({
                url: this.$store.state.url + '/api/transport/get',
                data: {
                  token: this.$store.state.token,
                  type: 'single',
                  id: this.$route.params.id,
                },
                method: 'POST',
              })
                .then(resp => {
                  if (resp.data.status === 200) {
                    let t = resp.data.transports[0];
                    this.transport.price = t.price;
                    this.transport.priceVat = t.priceVat;
                    this.transport.params = t.packages ? JSON.parse(t.packages) : null;
                    this.packages.other = this.transport.params;
                    this.loadingProgress = false;
                    this.addEditDialog = false;
                  } else {
                    this.messageM(
                      {
                        content: resp.data.message,
                        type: 'error',
                      },
                      true
                    );
                    this.loadingProgress = false;
                  }
                })
                .catch(() => {
                  this.messageM(
                    {
                      content: 'Nieoczekiwany błąd. Odśwież stronę i spróbuj ponownie',
                      type: 'error',
                    },
                    true
                  );
                  this.loadingProgress = false;
                });
            } else {
              this.messageM(
                {
                  content: resp.data.message,
                  type: 'error',
                },
                true
              );
              this.loadingProgress = false;
            }
          })
          .catch(() => {
            this.messageM(
              {
                content: 'Nieoczekiwany błąd. Odśwież stronę i spróbuj ponownie',
                type: 'error',
              },
              true
            );
            this.loadingProgress = false;
          });
      },
      messageM: function (message, clear = false) {
        if (clear) {
          this.resetSnackbar();
          this.snackbarMessageList = [message];
        } else {
          this.snackbarMessageList.push(message);
        }
      },
      resetSnackbar: function () {
        this.snackbarMessageList = [];
        this.snackbar = false;
      },

      variantFormatName: function (variant) {
        return (
          (variant.variant_name || variant.name || '') + (variant.SKU ? ` / ${variant.SKU}` : '')
        );
      },
      packageHeaders: function (item) {
        const tmp = [
          {
            text: 'LP',
            value: 'index',
            sortable: false,
          },
          {
            text: 'Długość',
            value: 'height',
            sortable: false,
          },
          {
            text: 'Szerokość',
            value: 'width',
            sortable: false,
          },
          {
            text: 'Wysokość',
            value: 'depth',
            sortable: false,
          },
          {
            text: 'J. wymiaru',
            value: 'dimensions_unit',
            sortable: false,
          },
          {
            text: 'Waga',
            value: 'weight',
            sortable: false,
          },
          {
            text: 'J. wagi',
            value: 'measure_unit',
            sortable: false,
          },
          {
            text: '',
            value: 'actions',
            sortable: false,
          },
        ];

        if (item.item_type === 'chairs')
          tmp.splice(7, 0, {
            text: 'Karton',
            value: 'cardboard_boxes',
            sortable: false,
            width: '92px',
          });

        return tmp;
      },
      itemTypeMapper: function (itemType) {
        switch (itemType) {
          case 'hard_furniture':
            return 'Meble paczkowane';
          case 'upholstered_furniture':
            return 'Meble twarde';
          case 'chairs':
            return 'Krzesła';
          case 'tables':
            return 'Stoły';
          default:
            return null;
        }
      },
      confirmItems: function (n, item) {
        const index = this.transport.params.indexOf(item);

        this.loadingProgress = true;
        axios({
          url: this.$store.state.url + '/api/transport/items/received',
          data: {
            token: this.$store.state.token,
            transport_id: this.$route.params.id,
            item: item,
            index: index,
            num: n,
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status === 200) {
              this.transport.params[index].received = n;
              this.editConfirmItemsDialog = false;
              this.editConfirmItem = null;
            } else {
              this.message = resp.data.message;
              this.alert = true;
            }

            this.loadingProgress = false;
          })
          .catch(err => {
            this.message = err;
            this.alert = true;
            this.loadingProgress = false;
          });
      },
      editTransportPackages: function (itemsPackages) {
        this.loadingProgress = true;
        axios({
          url: this.$store.state.url + '/api/transport/modify/itemsV2',
          data: {
            token: this.$store.state.token,
            transport_id: this.$route.params.id,
            packages: itemsPackages,
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status === 200) {
              this.transport.params = itemsPackages;
              this.editItemsDialog = false;
            } else this.info = resp.data;

            this.loadingProgress = false;
          })
          .catch(err => {
            this.info = err;
            this.loadingProgress = false;
          });
      },
    },
    created() {
      axios({
        url: this.$store.state.url + '/api/transport/get',
        data: {
          token: this.$store.state.token,
          type: 'single',
          id: this.$route.params.id,
        },
        method: 'POST',
      }).then(resp => {
        if (resp.data.status === 200) {
          for (let [i, t] of resp.data.transports.entries()) {
            let date = t.date_expected === null ? new Date('0000-00-00') : new Date(t.date_expected);
            if (!date || isNaN(date.getTime())) {
              t.date_expected = null;
            } else {
              let year = date.getFullYear();
              let month = date.getMonth() + 1;
              let dt = date.getDate();

              if (dt < 10) dt = '0' + dt;
              if (month < 10) month = '0' + month;

              t.date_expected = dt + '.' + month + '.' + year;
              this.deliveryDate = `${year}-${month}-${dt}`;
              this.deliveryDateCopy = `${year}-${month}-${dt}`;
            }

            date = new Date(t.pickup_date);
            if (date == NaN) {
              t.pickup_date = null;
            } else {
              let year = date.getFullYear();
              let month = date.getMonth() + 1;
              let dt = date.getDate();

              if (dt < 10) dt = '0' + dt;
              if (month < 10) month = '0' + month;

              t.pickup_date = dt + '.' + month + '.' + year;
              this.pickupDate = `${year}-${month}-${dt}`;
              this.pickupDateCopy = `${year}-${month}-${dt}`;
            }

            date = new Date(t.date_created);
            if (date.getDate()) {
              let year = date.getFullYear();
              let month = date.getMonth() + 1;
              let dt = date.getDate();
              let hr = date.getHours();
              let min = date.getMinutes();

              if (dt < 10) dt = '0' + dt;
              if (month < 10) month = '0' + month;
              if (hr < 10) hr = '0' + hr;
              if (min < 10) min = '0' + min;

              t.date_created = dt + '.' + month + '.' + year + ' ' + hr + ':' + min;
            } else {
              t.date_created = null;
            }

            const tmpT = {
              id: i + 1,
              tid: t.transport_id,
              t_number: t.t_number,
              content: t.content,
              info: t.info,
              params: t.packages ? JSON.parse(t.packages) : null,
              price: t.price,
              priceVat: t.priceVat,
              inAdvance: t.payed_in_advance ? true : false,
              created: t.date_created,
              pickup_date: t.pickup_date,
              pickup_price: t.pickup_price,
              expected: t.date_expected,
              value: t.price + ' zł',
              status: t.status,
              money_to_collect: t.payed_in_advance ? t.money_to_collect : null,
              payment_id: t.payment_id,
              pickup_payment_id: t.pickup_payment_id,
              type_0:
                t.width == 0 && t.height == 0 && (t.depth == 0) & (t.weight == 0) ? true : false,
              user_id: t.user_id,
              extension: t.extension,
              extra_info: t.extra_info ? JSON.parse(t.extra_info) : { driverInfo: {}, delivery: {} },
              streamsoft_transport_id: t.streamsoft_transport_id,
            };
            if (!tmpT.extra_info.driverInfo) tmpT.extra_info.driverInfo = {};
            if (!tmpT.extra_info.delivery) tmpT.extra_info.delivery = {};

            this.transport = tmpT;

            let cnt = 1;
            this.transport.params.forEach(element => {
              element.index = cnt++;

              if (element.category) {
                this.packages.hard.push(element);
              } else {
                this.packages.other.push(element);
              }
            });

            // is new format?
            if (tmpT.params && tmpT.params[0] && tmpT.params[0].item_type) this.newFormat = true;

            let split = t.date_expected ? t.date_expected.split('.') : [];
            date = new Date(split[2] + '-' + split[1] + '-' + split[0]);

            if (!date.getTime()) {
              this.editDate.date = null;
              this.editDate.defaultDate = null;
              this.transport.expected = null;
            } else {
              let year = date.getFullYear();
              let month = date.getMonth() + 1;
              let dt = date.getDate();

              if (dt < 10) dt = '0' + dt;
              if (month < 10) month = '0' + month;

              this.editDate.date = year + '-' + month + '-' + dt;
              this.editDate.defaultDate = this.editDate.date;
            }

            this.mediaIds = t.media_ids ? t.media_ids.split(',') : [];
          }

          this.receiver = resp.data.receiver[0];

          if (this.receiver) {
            this.maps.delivery = tt.map({
              key: this.$store.state.maps.key,
              language: this.$store.state.maps.lang,
              container: 'deliveryMap',
              center: [this.receiver.lng, this.receiver.lat],
              style: this.getMapStyle(),
              zoom: 13,
            });

            this.markers.delivery = new tt.Marker()
              .setLngLat([this.receiver.lng, this.receiver.lat])
              .addTo(this.maps.delivery);
          }

          this.sender = resp.data.sender[0];

          if (this.sender) {
            this.maps.pickup = tt.map({
              key: this.$store.state.maps.key,
              language: this.$store.state.maps.lang,
              container: 'pickupMap',
              center: [this.sender.lng, this.sender.lat],
              style: this.getMapStyle(),
              zoom: 13,
            });

            this.markers.pickup = new tt.Marker()
              .setLngLat([this.sender.lng, this.sender.lat])
              .addTo(this.maps.pickup);
          }

          if (this.receiver && this.sender) {
            this.maps.full = tt.map({
              key: this.$store.state.maps.key,
              language: this.$store.state.maps.lang,
              container: 'fullMap',
              center: [19.252522, 52.065162],
              style: this.getMapStyle(),
              zoom: 4,
            });

            this.markers.pickupFull = new tt.Marker()
              .setLngLat([this.sender.lng, this.sender.lat])
              .addTo(this.maps.full);

            this.markers.deliveryFull = new tt.Marker()
              .setLngLat([this.receiver.lng, this.receiver.lat])
              .addTo(this.maps.full);

            this.panels = [0];

            var origins = [
              {
                point: {
                  latitude: this.sender.lat,
                  longitude: this.sender.lng,
                },
              },
            ];
            var destinations = [
              {
                point: {
                  latitude: this.receiver.lat,
                  longitude: this.receiver.lng,
                },
              },
            ];

            ts.services
              .matrixRouting({
                key: this.$store.state.maps.key,
                origins: origins,
                destinations: destinations,
                traffic: true,
              })
              .go()
              .then(this.onRouteLoad);

            ts.services
              .calculateRoute({
                key: this.$store.state.maps.key,
                locations: `${this.receiver.lng},${this.receiver.lat}:${this.sender.lng},${this.sender.lat}`,
              })
              .go()
              .then(response => {
                let geojson = response.toGeoJson();

                this.maps.full.addLayer({
                  id: 'route',
                  type: 'line',
                  source: {
                    type: 'geojson',
                    data: geojson,
                  },
                  paint: {
                    'line-color': '#800080',
                    'line-width': 8,
                  },
                });

                var bounds = new tt.LngLatBounds();
                geojson.features[0].geometry.coordinates.forEach(function (point) {
                  bounds.extend(tt.LngLat.convert(point));
                });
                this.maps.full.fitBounds(bounds, { padding: 20 });
              });
          }

          let user = resp.data.user;
          if (user) {
            this.user = {
              name:
                user.company == null || user.company == 0
                  ? user.name + ' ' + user.surname
                  : user.name,
              street: user.company == null || user.company == 0 ? null : user.address,
              postcode: user.postcode,
              city: user.city,
              NIP: user.company == null && user.company == 0 ? null : user.NIP,
              active: user.active ? true : false,
            };
          }

          if (resp.data.transports[0].invoice_data == null) {
            // dane takie jak firma
            if (resp.data.transports[0].company) {
              this.invoice_data = Object.assign({}, this.user);
            } else {
              this.invoice_data = null;
            }
          } else if (resp.data.transports[0].invoice_data == 0) {
            // paragon
            this.invoice_data = null;
          } else {
            // inne dane
            this.invoice_data = JSON.parse(resp.data.transports[0].invoice_data);
          }

          if (resp.data.status_logs) {
            this.status_logs = resp.data.status_logs;
          }
        } else {
          this.$router.push('/transport/wszystkie');
        }

        if (this.isAdmin) {
          this.subject = 'Wiadomość do zamówienia ' + this.transport.t_number;
        } else if (this.transport.status < 5) {
          // STATUS:
          this.subject = 'Uwagi do zamówienia ' + this.transport.t_number;
        } else {
          this.subject = 'Wiadomość do zamówienia ' + this.transport.t_number;
        }
      });
    },
  };
</script>
<style scoped>
  .v-expansion-panel .map {
    width: 100%;
    height: 300px;
    opacity: 0;
    visibility: hidden;
    transform: scaleY(0);
    transition: 0.1s;
  }
  .v-expansion-panel.v-item--active .map {
    display: block;
    visibility: visible;
    transform: scaleY(1);
    opacity: 1;
  }
  .v-list-item__subtitle {
    white-space: normal;
  }
</style>
<style>
  .v-btn--outlined {
    border: thin solid #ffc400 !important;
  }
  .btn--active-prevent {
    color: rgba(0, 0, 0, 0.6) !important;
  }
</style>
