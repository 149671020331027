<template>
  <v-card class="mx-auto">
    <v-toolbar flat color="black" :dark="true">
      <v-toolbar-title>Edytowanie przesyłek</v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-card-text class="py-0">
      <v-form
        v-if="hardPackages.length"
        ref="hardForm"
        v-model="validHard"
        lazy="true"
        class="pl-4 mt-4"
      >
        <h3 class="grey--text text--darken-2">Meble twarde</h3>

        <v-row v-for="(item, index) in hardPackages" :key="index" align="center" justify="center">
          <v-col cols="12" sm="7" class="my-0 py-0">
            <v-text-field
              v-model="item.name"
              :rules="rulesContent"
              label="nazwa"
              name="name"
              type="text"
            />
          </v-col>
          <v-col cols="12" sm="4" class="my-0 py-0">
            <v-text-field
              v-model="item.color"
              :rules="rulesContent"
              label="wybarwienie"
              name="color"
              type="text"
            />
          </v-col>
          <v-col
            cols="1"
            class="d-flex align-center justify-center"
            @click="removeItem(index, 'hard')"
          >
            <v-icon color="error">mdi-delete</v-icon>
          </v-col>
          <v-col cols="12" sm="4" class="my-0 py-0">
            <v-text-field
              v-model="item.volume"
              :rules="floatWithoutZeroRule"
              label="objętość"
              name="volume"
              type="number"
              suffix="m³"
              min="0"
            />
          </v-col>
          <v-col cols="12" sm="4" class="my-0 py-0">
            <v-text-field
              v-model="item.weight"
              :rules="floatWithoutZeroRule"
              label="waga"
              name="weight"
              type="number"
              suffix="kg"
              min="0"
            />
          </v-col>
          <v-col cols="12" sm="4" class="my-0 py-0">
            <v-text-field
              v-model="item.number"
              :rules="integerPositiveWithoutZero"
              label="ilość paczek"
              name="amount"
              type="number"
              suffix="szt"
              min="1"
            />
          </v-col>
          <v-divider class="mx-2 mb-4"></v-divider>
        </v-row>
      </v-form>

      <v-form
        v-if="otherPackages.length"
        ref="otherForm"
        v-model="validOther"
        lazy="true"
        class="pl-4 mt-4"
      >
        <h3 class="grey--text text--darken-2">Meble tapicerowane i inne</h3>

        <v-row v-for="(item, index) in otherPackages" :key="index" align="center" justify="center">
          <v-col cols="12" sm="7" class="my-0 py-0">
            <v-text-field
              v-model="item.name"
              :rules="rulesContent"
              label="nazwa"
              name="name"
              type="text"
            />
          </v-col>
          <v-col cols="12" sm="4" class="my-0 py-0">
            <v-text-field
              v-model="item.color"
              :rules="rulesContent"
              label="wybarwienie"
              name="color"
              type="text"
            />
          </v-col>
          <v-col
            cols="1"
            class="d-flex align-center justify-center"
            @click="removeItem(index, 'other')"
          >
            <v-icon color="dark">mdi-delete</v-icon>
          </v-col>
          <v-col cols="12" sm="3" class="my-0 py-0">
            <v-text-field
              v-model="item.width"
              :rules="integerPositiveWithoutZero"
              label="szerokość"
              name="width"
              type="number"
              suffix="cm"
              min="1"
            />
          </v-col>
          <v-col cols="12" sm="3" class="my-0 py-0">
            <v-text-field
              v-model="item.height"
              :rules="integerPositiveWithoutZero"
              label="długość"
              name="height"
              type="number"
              suffix="cm"
              min="1"
            />
          </v-col>
          <v-col cols="12" sm="3" class="my-0 py-0">
            <v-text-field
              v-model="item.depth"
              :rules="integerPositiveWithoutZero"
              label="wysokość"
              name="depth"
              type="number"
              suffix="cm"
              min="1"
            />
          </v-col>
          <v-col cols="12" sm="3" class="my-0 py-0">
            <v-text-field
              v-model="item.weight"
              :rules="floatWithoutZeroRule"
              label="waga"
              name="weight"
              type="number"
              suffix="kg"
              min="0"
            />
          </v-col>
          <v-divider class="mx-2 mb-4"></v-divider>
        </v-row>
      </v-form>

      <v-row align="center" justify="center">
        <v-col cols="12">
          <v-btn
            block
            @click="packageModal = !packageModal"
            x-large
            dark
            color="primary"
            class="mx-auto"
          >
            <v-icon left>mdi-plus</v-icon> Dodaj Towar
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-col cols="12" sm="3">
        <v-btn
          block
          @click="disableDialog()"
          color="white"
          class="grey--text text--darken-4"
          :x-large="true"
          outlined
          >Anuluj</v-btn
        >
      </v-col>
      <v-spacer />
      <v-col cols="12" sm="3">
        <v-btn
          block
          @click="packagesUpdated()"
          :disabled="
            preventBtn ||
            !validHard ||
            !validOther ||
            hardPackages.length + otherPackages.length < 1
          "
          color="amber accent-3"
          class="grey--text text--darken-4"
          :x-large="true"
          v-tilt
          >Zmień</v-btn
        >
      </v-col>
    </v-card-actions>

    <PackageCreator
      :active="packageModal"
      @disableDialog="disablePackageModal"
      @createPackage="createPackage"
    ></PackageCreator>
  </v-card>
</template>

<script>
  import PackageCreator from './PackageCreator.vue';

  export default {
    name: 'TransportEditPackages',
    props: {
      active: Boolean,
      orderStatus: Number,
      oldHardPackages: Array,
      oldOtherPackages: Array,
    },
    components: {
      PackageCreator,
    },
    data: () => ({
      rulesContent: [v => !!v || 'Pole jest wymagane'],
      integerPositiveWithoutZero: [
        v => !!v || 'Pole jest wymagane',
        v => /^([1-9]\d*)$/.test(v) || 'Musi być liczbą naturalną',
      ],
      floatWithoutZeroRule: [
        v => !!v || 'Pole jest wymagane',
        v =>
          /^([1-9]\d*(\.|,)\d+|0(\.|,)0*[1-9]+\d*|[1-9]\d*)$/.test(v) ||
          'Liczba ma niepoprawny format (liczba musi być większa od 0)',
      ],
      hardPackages: [],
      otherPackages: [],

      validHard: true,
      validOther: true,
      preventBtn: false,

      packageModal: false,
    }),
    watch: {
      active: function (val) {
        if (val == false) {
          this.clear();
        }
      },
    },
    methods: {
      createPackage: function (item, type) {
        // STATUS:
        if (this.orderStatus > 2) {
          item.received = true;
        } else {
          item.received = false;
        }

        if (type === 'hard') {
          this.hardPackages.push(item);
        } else {
          this.otherPackages.push(item);
        }
      },
      disablePackageModal: function () {
        this.packageModal = false;
      },
      packagesUpdated: function () {
        this.preventBtn = true;
        this.$emit('packagesUpdated', this.hardPackages, this.otherPackages);
        this.disableDialog();
        this.preventBtn = false;
      },
      disableDialog: function () {
        this.$emit('disableDialog');
      },
      removeItem: function (index, type) {
        if (type === 'hard') {
          this.hardPackages.splice(index, 1);
        } else {
          this.otherPackages.splice(index, 1);
        }
      },
      clear: function () {
        this.hardPackages = JSON.parse(JSON.stringify(this.oldHardPackages));
        this.otherPackages = JSON.parse(JSON.stringify(this.oldOtherPackages));
      },
    },
    created() {
      this.hardPackages = JSON.parse(JSON.stringify(this.oldHardPackages));
      this.otherPackages = JSON.parse(JSON.stringify(this.oldOtherPackages));
    },
  };
</script>
<style>
  .v-btn--outlined {
    border: thin solid #ffc400 !important;
  }
</style>
