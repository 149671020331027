<template>
  <div>
    <v-progress-circular v-show="loading" indeterminate size="64"></v-progress-circular>
    <v-card v-show="!loading" class="mx-auto" max-width="800" outlined>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-textarea
              v-model="content"
              name="content"
              label="Twoje zgłoszenie"
              hint="Opisz swój problem w kilku słowach"
            ></v-textarea>
          </v-col>
          <v-col cols="12">
            <p>Załącz pliki</p>
            <vue-dropzone
              ref="myVueDropzone"
              id="dropzone"
              :options="dropzoneOptions"
              v-on:vdropzone-sending="onSend"
              v-on:vdropzone-success="onSuccess"
            ></vue-dropzone>
          </v-col>
        </v-row>
        <v-row justify="end">
          <v-spacer />
          <v-col cols="12" md="6">
            <v-btn
              @click="addTicket"
              :disabled="mediaIds.length < 1 || content.length < 1"
              color="amber accent-3"
              class="grey--text text--darken-4"
              :x-large="true"
              v-tilt
              >Wyślij zgłoszenie</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import vue2Dropzone from 'vue2-dropzone';
  import 'vue2-dropzone/dist/vue2Dropzone.min.css';
  import axios from 'axios';

  export default {
    name: 'Ticket',
    props: {
      transport: Number,
    },
    components: {
      vueDropzone: vue2Dropzone,
    },
    data: () => ({
      loading: true,
      dropzoneOptions: {
        url: `/api/media/upload`,
        thumbnailWidth: 150,
        maxFiles: 6,
        maxFilesize: 6,
        dictDefaultMessage: 'Prześlij zdjęcia problemu',
        addRemoveLinks: true,
        acceptedFiles: 'image/*',
      },
      mediaIds: [],
      content: '',
    }),
    methods: {
      onSend: function (file, xhr, formData) {
        formData.append('token', this.$store.state.token);
      },
      onSuccess: function (file, response) {
        this.mediaIds.push(response.mediaId);
      },
      addTicket: function () {
        axios({
          url: this.$store.state.url + '/api/ticket/create',
          data: {
            token: this.$store.state.token,
            transport_id: this.transport,
            media_ids: this.mediaIds,
            content: this.content,
          },
          method: 'POST',
        })
          .then(resp => {
            //put here correct redirection after payment adding
            this.$router.push({
              name: 'ticketsSingle',
              params: {
                id: resp.data.ticketId,
              },
            });
          })
          .catch(() => {});
      },
    },
    created() {
      this.dropzoneOptions.url = `${this.$store.state.url}/api/media/upload`;
      setTimeout(() => {
        this.loading = false;
      }, 200);
    },
  };
</script>
