<template>
  <v-container fluid class="grey lighten-4 bottom-spacer">
    <v-dialog
      v-model="active"
      :dark="false"
      :light="true"
      :max-width="max_width ? max_width : '800px'"
      no-click-animation
      persistent
    >
      <v-card class="mx-auto pb-1" :max-width="max_width ? max_width : '800px'">
        <v-toolbar flat color="black" :dark="true">
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer />
        </v-toolbar>

        <v-data-table
          :headers="headers"
          :items="editedItems"
          :single-expand="true"
          :expanded.sync="expanded"
          item-key="index"
          show-expand
          hide-default-footer
          disable-pagination
          class="elevation-1"
        >
          <!-- eslint-disable-next-line -->
          <template v-slot:item.price="{ item }">
            {{
              item.packages
                .reduce((total, obj) => {
                  return total + (obj.price ? parseFloat(obj.price) : 0);
                }, 0)
                .toFixed(2)
            }}
            zł
          </template>

          <template v-slot:[`item.data-table-expand`]="{ expand, isExpanded, item }">
            <v-row no-gutters dense v-if="!isExpanded">
              <v-col cols="6">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="error"
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="removeVariant(item)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Usuń</span>
                </v-tooltip>
              </v-col>
              <v-col cols="6">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="primary"
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="editVariant(expand, isExpanded, item)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edytuj</span>
                </v-tooltip>
              </v-col>
            </v-row>

            <v-row no-gutters dense v-else>
              <v-col cols="6">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="success"
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="acceptVariant(expand, isExpanded, item)"
                    >
                      <v-icon>mdi-checkbox-marked-circle</v-icon>
                    </v-btn>
                  </template>
                  <span>Zatwierdź zmiany</span>
                </v-tooltip>
              </v-col>

              <v-col cols="6">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="error"
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="cancelVariant(expand, isExpanded, item)"
                    >
                      <v-icon>mdi-cancel</v-icon>
                    </v-btn>
                  </template>
                  <span>Odrzuć zmiany</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-form ref="formName">
                <v-row class="mx-4 my-2 pa-auto">
                  <v-col lg="8" md="8" sm="8" cols="12">
                    <v-text-field
                      v-model="item.name"
                      label="Nazwa przedmiotu"
                      :rules="lengthRule(1, 255)"
                      maxlength="64"
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col lg="4" md="4" sm="4" cols="12">
                    <v-text-field
                      v-model="item.category_name"
                      label="Kategoria"
                      :rules="lengthRule(1, 255)"
                      maxlength="64"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
              <v-divider></v-divider>
              <v-divider></v-divider>

              <v-form ref="formVariant" dense>
                <v-row class="mx-4 my-2 pa-auto">
                  <v-col lg="9" md="9" sm="9" cols="12">
                    <v-text-field
                      v-model="item.variant_name"
                      label="Nazwa wariantu (opcjonalne)"
                      :rules="lengthRule(0, 255)"
                      maxlength="64"
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col lg="6" md="6" sm="6" cols="12">
                    <v-text-field
                      v-model="item.color"
                      label="Wybarwienie"
                      :rules="lengthRule(1, 64)"
                      maxlength="64"
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col lg="6" md="6" sm="6" cols="12">
                    <v-text-field
                      v-model="item.SKU"
                      label="SKU (opcjonalnie)"
                      :rules="lengthRule(0, 32)"
                      maxlength="32"
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col lg="12" md="12" sm="12" cols="12">
                    <v-data-table
                      :headers="packageHeaders"
                      :items="item.packages"
                      hide-default-footer
                      disable-pagination
                      dense
                    >
                      <template v-slot:top>
                        <p class="font-weight-medium">Paczki</p>
                      </template>

                      <template v-slot:[`item.width`]="props">
                        <v-edit-dialog
                          :return-value="props.item.width"
                          @save="savePackage()"
                          @cancel="cancelPackage()"
                          @open="openPackage()"
                          @close="closePackage()"
                        >
                          {{ props.item.width }}
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon color="primary" x-small v-bind="attrs" v-on="on">
                                <v-icon>mdi-pencil</v-icon>
                              </v-btn>
                            </template>
                            <span>Edytuj</span>
                          </v-tooltip>

                          <template v-slot:input>
                            <v-text-field
                              v-model="props.item.width"
                              single-line
                              label="Szerokość"
                              :rules="float(9, 5)"
                              type="number"
                              maxlength="14"
                              min="0"
                              dense
                              autofocus
                            ></v-text-field>
                          </template>
                        </v-edit-dialog>
                      </template>

                      <template v-slot:[`item.height`]="props">
                        <v-edit-dialog
                          :return-value="props.item.height"
                          @save="savePackage()"
                          @cancel="cancelPackage()"
                          @open="openPackage()"
                          @close="closePackage()"
                        >
                          {{ props.item.height }}
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon color="primary" x-small v-bind="attrs" v-on="on">
                                <v-icon>mdi-pencil</v-icon>
                              </v-btn>
                            </template>
                            <span>Edytuj</span>
                          </v-tooltip>

                          <template v-slot:input>
                            <v-text-field
                              v-model="props.item.height"
                              single-line
                              label="Długość"
                              :rules="float(9, 5)"
                              type="number"
                              maxlength="14"
                              min="0"
                              dense
                              autofocus
                            ></v-text-field>
                          </template>
                        </v-edit-dialog>
                      </template>

                      <template v-slot:[`item.depth`]="props">
                        <v-edit-dialog
                          :return-value="props.item.depth"
                          @save="savePackage()"
                          @cancel="cancelPackage()"
                          @open="openPackage()"
                          @close="closePackage()"
                        >
                          {{ props.item.depth }}
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon color="primary" x-small v-bind="attrs" v-on="on">
                                <v-icon>mdi-pencil</v-icon>
                              </v-btn>
                            </template>
                            <span>Edytuj</span>
                          </v-tooltip>

                          <template v-slot:input>
                            <v-text-field
                              v-model="props.item.depth"
                              single-line
                              label="Wysokość"
                              :rules="float(9, 5)"
                              type="number"
                              maxlength="14"
                              min="0"
                              dense
                              autofocus
                            ></v-text-field>
                          </template>
                        </v-edit-dialog>
                      </template>

                      <template v-slot:[`item.dimensions_unit`]="props">
                        <v-edit-dialog
                          :return-value="props.item.dimensions_unit"
                          @save="savePackage()"
                          @cancel="cancelPackage()"
                          @open="openPackage()"
                          @close="closePackage()"
                        >
                          {{ props.item.dimensions_unit }}
                          <template v-slot:input>
                            <v-select
                              v-model="props.item.dimensions_unit"
                              :items="dimensions_unit"
                              dense
                            ></v-select>
                          </template>
                        </v-edit-dialog>
                      </template>

                      <template v-slot:[`item.weight`]="props">
                        <v-edit-dialog
                          :return-value="props.item.weight"
                          @save="savePackage()"
                          @cancel="cancelPackage()"
                          @open="openPackage()"
                          @close="closePackage()"
                        >
                          {{ props.item.weight }}
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon color="primary" x-small v-bind="attrs" v-on="on">
                                <v-icon>mdi-pencil</v-icon>
                              </v-btn>
                            </template>
                            <span>Edytuj</span>
                          </v-tooltip>

                          <template v-slot:input>
                            <v-text-field
                              v-model="props.item.weight"
                              single-line
                              label="Waga"
                              :rules="float(9, 5)"
                              type="number"
                              maxlength="14"
                              min="0"
                              dense
                              autofocus
                            ></v-text-field>
                          </template>
                        </v-edit-dialog>
                      </template>

                      <template v-slot:[`item.measure_unit`]="props">
                        <v-edit-dialog
                          :return-value="props.item.measure_unit"
                          @save="savePackage()"
                          @cancel="cancelPackage()"
                          @open="openPackage()"
                          @close="closePackage()"
                        >
                          {{ props.item.measure_unit }}
                          <template v-slot:input>
                            <v-select
                              v-model="props.item.measure_unit"
                              :items="measure_unit"
                              dense
                            ></v-select>
                          </template>
                        </v-edit-dialog>
                      </template>

                      <template v-slot:[`item.price`]="props" v-if="isAdmin">
                        <v-edit-dialog
                          :return-value="props.item.price"
                          @save="savePackage()"
                          @cancel="cancelPackage()"
                          @open="openPackage()"
                          @close="closePackage()"
                        >
                          {{ props.item.price ? props.item.price + ' zł' : '' }}
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon color="primary" x-small v-bind="attrs" v-on="on">
                                <v-icon>mdi-pencil</v-icon>
                              </v-btn>
                            </template>
                            <span>Edytuj</span>
                          </v-tooltip>

                          <template v-slot:input>
                            <v-text-field
                              v-model="props.item.price"
                              :rules="float(10, 4, true, true, false)"
                              type="number"
                              maxlength="14"
                              min="0"
                              suffix="zł"
                              dense
                              autofocus
                            ></v-text-field>
                          </template>
                        </v-edit-dialog>
                      </template>

                      <template v-slot:[`item.actions`]="{ item }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              color="error"
                              x-small
                              v-bind="attrs"
                              v-on="on"
                              @click="removePackage(item)"
                            >
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </template>
                          <span>Usuń</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-col>

                  <v-col lg="12" md="12" sm="12" cols="12" align="center">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          color="blue-grey"
                          class="grey--text text--darken-4 mx-auto"
                          small
                          v-bind="attrs"
                          v-on="on"
                          @click="addPackage(item)"
                        >
                          <v-icon>mdi-plus-circle</v-icon>
                        </v-btn>
                      </template>
                      <span>Dodaj paczkę</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-form>
            </td>
          </template>
        </v-data-table>

        <v-row class="mx-4 my-4 pa-auto" v-if="!expanded.length">
          <v-btn block @click="addVariant()" x-large dark color="primary" class="mx-auto">
            <v-icon left>mdi-plus</v-icon> Dodaj przedmiot
          </v-btn>
        </v-row>

        <v-row class="mx-4 my-2 pa-auto">
          <v-btn
            color="amber accent-3"
            class="grey--text text--darken-4"
            outlined
            :x-large="true"
            v-tilt
            @click="closeDialog()"
          >
            Anuluj
          </v-btn>
          <v-spacer />
          <v-btn
            color="amber accent-3"
            class="grey--text text--darken-4"
            :x-large="true"
            v-tilt
            @click="editItem()"
          >
            Zapisz
          </v-btn>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- confirm dialog -->
    <ConfirmDialog ref="confirmDialog"></ConfirmDialog>
  </v-container>
</template>

<script>
  import store from '../store';
  import ConfirmDialog from './Confirm.vue';

  export default {
    name: 'AddEditItemsAdmin',
    components: {
      ConfirmDialog,
    },
    props: {
      title: String,
      items: Array,
      max_width: String,
    },
    data: () => ({
      active: true,
      isAdmin: store.getters.isAdmin,

      dimensions_unit: ['m', 'dm', 'cm'],
      measure_unit: ['kg'], //, "g"],
      package_unit: ['szt'],
      editedItems: [
        {
          item_id: null,
          name: '',
          category_name: '',
          item_variant_id: null,
          variant_name: '',
          SKU: '',
          color: '',
          packages: [
            {
              width: '',
              height: '',
              depth: '',
              dimensions_unit: 'cm',
              weight: '',
              measure_unit: 'kg',
              price: '',
              index: 1,
            },
          ],
          index: 1,
        },
      ],
      editedVariant: null,

      counterVariant: 1,
      expanded: [],
      headers: [
        {
          text: 'LP',
          value: 'index',
          sortable: false,
        },
        {
          text: 'Nazwa przedmiotu',
          value: 'name',
          sortable: false,
        },
        {
          text: 'Nazwa kategorii',
          value: 'category_name',
          sortable: false,
        },
        {
          text: 'Nazwa wariantu',
          value: 'variant_name',
          sortable: false,
        },
        {
          text: 'Wybarwienie',
          value: 'color',
          sortable: false,
        },
        {
          text: 'SKU',
          value: 'SKU',
          sortable: false,
        },
        {
          text: 'Cena',
          value: 'price',
          sortable: false,
        },
        {
          text: '',
          value: 'data-table-expand',
          sortable: false,
          width: '80px',
        },
      ],

      packageHeaders: [
        {
          text: 'LP',
          value: 'index',
          sortable: false,
        },
        {
          text: 'Szerokość',
          value: 'width',
          sortable: false,
        },
        {
          text: 'Długość',
          value: 'height',
          sortable: false,
        },
        {
          text: 'Wysokość',
          value: 'depth',
          sortable: false,
        },
        {
          text: 'J. wymiaru',
          value: 'dimensions_unit',
          sortable: false,
        },
        {
          text: 'Waga',
          value: 'weight',
          sortable: false,
        },
        {
          text: 'J. wagi',
          value: 'measure_unit',
          sortable: false,
        },
        {
          text: 'Cena',
          value: 'price',
          sortable: false,
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
        },
      ],

      preventBtn: true,
    }),
    watch: {},
    methods: {
      integer: function (from, to, required = true, positive = true, notZero = true) {
        let rules = [];

        if (required) {
          rules.push(v => !!v || 'Pole jest wymagane');
          rules.push(v => new RegExp(`^(0|[1-9]\\d*)$`).test(v) || 'Zły format liczby');
          rules.push(
            v =>
              new RegExp(`^.{${from},${to}}$`).test(v) ||
              `Liczba musi zawierac od ${from} do ${to} cyfr`
          );
        } else {
          rules.push(v => new RegExp(`^$|^(0|[1-9]\\d*)$`).test(v) || 'Zły format liczby');
          rules.push(
            v =>
              new RegExp(`^$|^.{${from},${to}}$`)
                .test(v)(new RegExp(`^.{${from},${to}}$`))
                .test(v) || `Liczba musi zawierac od ${from} do ${to} cyfr`
          );
        }

        if (positive) {
          rules.push(v => v >= 0 || 'Pole musi być dodatnią liczbą');
        }

        if (notZero) {
          rules.push(v => v != 0 || 'Pole musi być różne od zera');
        }

        return rules;
      },
      float: function (to_the_left, to_the_right, required = true, positive = true, notZero = true) {
        let rules = [];

        let left = '0';
        if (to_the_left > 0) {
          left = `-?(0|([1-9][0-9]{0,${to_the_left - 1}}))`;
        }

        let right = '';
        if (to_the_right > 0) {
          right = `((\\.|,)[0-9]{0,${to_the_right - 1}}[1-9])?`;
        }

        if (required) {
          rules.push(v => !!v || 'Pole jest wymagane');
          rules.push(v => new RegExp(`^(${left + right})$`).test(v) || 'Zły format liczby');
        } else {
          rules.push(v => new RegExp(`^$|^(${left + right})$`).test(v) || 'Zły format liczby');
        }

        if (positive) {
          rules.push(v => v >= 0 || 'Pole musi być dodatnią liczbą');
        }

        if (notZero) {
          if (required) {
            rules.push(v => v != 0 || 'Pole musi być różne od zera');
          } else {
            rules.push(v => v == '' || v != 0 || 'Pole musi być różne od zera');
          }
        }

        return rules;
      },
      lengthRule: function (min_length, max_length) {
        return [
          v =>
            new RegExp(`^.{${min_length},${max_length}}$`).test(v) ||
            `Pole musi zawierac od ${min_length} do ${max_length} znaków`,
        ];
      },
      validatePackage: function (item, message = true) {
        for (let index = 0; index < item.packages.length; index++) {
          let inx = index;
          let pck = item.packages[index];

          let float_width = this.float(9, 5);
          for (let index = 0; index < float_width.length; index++) {
            let valid = float_width[index](pck.width);
            if (valid !== true) {
              if (message) {
                this.$emit('message', {
                  content: `Szerokość ma niepoprawny format (paczka nr ${
                    inx + 1
                  }). Uzupełnij poprawnie szerokość!`,
                  type: 'error',
                  timeout: 2000,
                });
              }
              return false;
            }
          }

          let float_height = this.float(9, 5);
          for (let index = 0; index < float_height.length; index++) {
            let valid = float_height[index](pck.height);
            if (valid !== true) {
              if (message) {
                this.$emit('message', {
                  content: `Długość ma niepoprawny format (paczka nr ${
                    inx + 1
                  }). Uzupełnij poprawnie długość!`,
                  type: 'error',
                  timeout: 2000,
                });
              }
              return false;
            }
          }

          let float_depth = this.float(9, 5);
          for (let index = 0; index < float_depth.length; index++) {
            let valid = float_depth[index](pck.depth);
            if (valid !== true) {
              if (message) {
                this.$emit('message', {
                  content: `Wysokość ma niepoprawny format (paczka nr ${
                    inx + 1
                  }). Uzupełnij poprawnie wysokość!`,
                  type: 'error',
                  timeout: 2000,
                });
              }
              return false;
            }
          }

          if (!pck.dimensions_unit) {
            if (message) {
              this.$emit('message', {
                content: `Jednostka wymiarów ma niepoprawny format (paczka nr ${
                  inx + 1
                }). Uzupełnij poprawnie jednostkę wymiaru!`,
                type: 'error',
                timeout: 2000,
              });
            }
            return false;
          }

          let float_weight = this.float(9, 5);
          for (let index = 0; index < float_weight.length; index++) {
            let valid = float_weight[index](pck.weight);
            if (valid !== true) {
              if (message) {
                this.$emit('message', {
                  content: `Waga ma niepoprawny format (paczka nr ${
                    inx + 1
                  }). Uzupełnij poprawnie wagę!`,
                  type: 'error',
                  timeout: 2000,
                });
              }
              return false;
            }
          }

          if (!pck.measure_unit) {
            if (message) {
              this.$emit('message', {
                content: `Jednostka wagi ma niepoprawny format (paczka nr ${
                  inx + 1
                }). Uzupełnij poprawnie jednostkę wagi!`,
                type: 'error',
                timeout: 2000,
              });
            }
            return false;
          }

          let float_price = this.float(10, 4, true, true, false);
          for (let index = 0; index < float_price.length; index++) {
            let valid = float_price[index](pck.price);
            if (valid !== true) {
              if (message) {
                this.$emit('message', {
                  content: `Cena ma niepoprawny format (paczka nr ${
                    inx + 1
                  }). Uzupełnij poprawnie cenę (cena jest wymagana)!`,
                  type: 'error',
                  timeout: 2000,
                });
              }
              return false;
            }
          }
        }

        return true;
      },
      addPackage: function (item) {
        if (!this.validatePackage(item)) {
          return;
        }

        item.packages.push({
          index: item.packages.length + 1,
          package_id: null,
          item_variant_id: item.item_variant_id,
          width: '',
          height: '',
          depth: '',
          dimensions_unit: 'cm',
          weight: '',
          measure_unit: 'kg',
          price: '',
        });
      },
      removePackage: async function (item, dialog = true) {
        if (
          dialog &&
          !(await this.$refs.confirmDialog.open(
            'Usuń paczkę',
            'Czy jesteś pewny, że chcesz usunąć paczkę?',
            { color: 'red' }
          ))
        ) {
          return;
        }

        let variant = this.expanded[0];
        let index = variant.packages.findIndex(obj => obj.index == item.index);
        if (index === -1) {
          /* empty */
        } else {
          variant.packages.splice(index, 1);

          // fix indexes
          let cnt = 1;
          for (let index = 0; index < variant.packages.length; index++) {
            variant.packages[index].index = cnt++;
          }

          if (!variant.packages.length) {
            variant.packages = [
              {
                index: cnt++,
                package_id: null,
                item_variant_id: variant.item_variant_id,
                width: '',
                height: '',
                depth: '',
                dimensions_unit: 'cm',
                weight: '',
                measure_unit: 'kg',
                price: '',
              },
            ];

            this.$emit('message', {
              content: 'Wariant musi zawierać przynajmniej jedną paczkę',
              type: 'error',
            });
          }
        }
      },
      validateVariant: function (variant, message = true) {
        let length_variant_name = this.lengthRule(0, 255);
        for (let index = 0; index < length_variant_name.length; index++) {
          let valid = length_variant_name[index](variant.variant_name);
          if (valid !== true) {
            if (message) {
              this.$emit('message', {
                content: `Nazwa wariantu nie może przekraczać 255 znaków (wariant nr ${variant.index})`,
                type: 'error',
                timeout: 1500,
              });
            }
            return false;
          }
        }

        let length_color = this.lengthRule(1, 64);
        for (let index = 0; index < length_color.length; index++) {
          let valid = length_color[index](variant.color);
          if (valid !== true) {
            if (message) {
              this.$emit('message', {
                content: `Wybarwienie musi zawierać od 1 do 64 znaków (wariant nr ${variant.index})`,
                type: 'error',
                timeout: 1500,
              });
            }
            return false;
          }
        }

        let length_SKU = this.lengthRule(0, 32);
        for (let index = 0; index < length_SKU.length; index++) {
          let valid = length_SKU[index](variant.SKU);
          if (valid !== true) {
            if (message) {
              this.$emit('message', {
                content: `SKU nie może przekraczać 32 znaków (wariant nr ${variant.index})`,
                type: 'error',
                timeout: 1500,
              });
            }
            return false;
          }
        }

        return true;
      },
      addVariant: function () {
        if (this.expanded.length) {
          return this.$emit('message', {
            content: 'Skończ edytować przedmiot, zanim dodasz następny',
            type: 'error',
          });
        }

        let tmp = {
          item_id: null,
          name: '',
          category_name: '',
          item_variant_id: null,
          variant_name: '',
          SKU: '',
          color: '',
          packages: [
            {
              width: '',
              height: '',
              depth: '',
              dimensions_unit: 'cm',
              weight: '',
              measure_unit: 'kg',
              price: '',
              index: 1,
            },
          ],
          index: this.counterVariant++,
        };

        this.editedItems.push(tmp);
        this.editedVariant = JSON.parse(JSON.stringify(tmp));
        this.expanded = [tmp];
      },
      editVariant: function (expand, isExpanded, item) {
        if (this.expanded.length) {
          return this.$emit('message', {
            content: 'Skończ edytować przedmiot, zanim edytujesz następny',
            type: 'error',
          });
        }

        this.editedVariant = JSON.parse(JSON.stringify(item));
        expand(!isExpanded);
      },
      removeVariant: async function (item, dialog = true) {
        if (this.expanded.length) {
          return this.$emit('message', {
            content: 'Skończ edytować przedmiot, zanim usuniesz inny',
            type: 'error',
          });
        }

        if (
          dialog &&
          !(await this.$refs.confirmDialog.open(
            'Usuń przedmiot',
            'Czy jesteś pewny, że chcesz usunąć przedmiot?',
            { color: 'red' }
          ))
        ) {
          return;
        }

        let index = this.editedItems.findIndex(obj => obj.index == item.index);
        if (index === -1) {
          /* empty */
        } else {
          this.editedItems.splice(index, 1);

          // fix indexes
          let cnt = 1;
          for (let index = 0; index < this.editedItems.length; index++) {
            this.editedItems[index].index = cnt++;
          }
          this.counterVariant = cnt;

          if (!this.editedItems.length) {
            let tmp = {
              item_id: null,
              name: '',
              category_name: '',
              item_variant_id: null,
              variant_name: '',
              SKU: '',
              color: '',
              packages: [
                {
                  width: '',
                  height: '',
                  depth: '',
                  dimensions_unit: 'cm',
                  weight: '',
                  measure_unit: 'kg',
                  price: '',
                  index: 1,
                },
              ],
              index: 1,
            };

            this.editedItems = [tmp];
            this.editedVariant = JSON.parse(JSON.stringify(tmp));
            this.expanded = [tmp];

            this.$emit('message', {
              content: 'Zamówienie musi zawierać przynajmniej jeden przedmiot',
              type: 'error',
            });
          }
        }
      },
      acceptVariant: function (expand, isExpanded, item) {
        if (this.$refs.formName) {
          this.$refs.formName.validate();
        }

        if (this.$refs.formVariant) {
          this.$refs.formVariant.validate();
        }

        let length_variant_name = this.lengthRule(1, 255);
        for (let index = 0; index < length_variant_name.length; index++) {
          let valid = length_variant_name[index](item.name);
          if (valid !== true) {
            this.$emit('message', {
              content: `Nazwa przedmiotu musi zawierać od 1 do 255 znaków`,
              type: 'error',
              timeout: 1500,
            });

            return;
          }
        }

        for (let index = 0; index < length_variant_name.length; index++) {
          let valid = length_variant_name[index](item.category_name);
          if (valid !== true) {
            this.$emit('message', {
              content: `Nazwa kategorii przedmiotu musi zawierać od 1 do 255 znaków`,
              type: 'error',
              timeout: 1500,
            });

            return;
          }
        }

        if (!this.validateVariant(item)) {
          return;
        }

        if (!this.validatePackage(item)) {
          return;
        }

        this.editedVariant = null;
        expand(!isExpanded);
      },
      cancelVariant: function (expand, isExpanded, item) {
        if (
          item.item_variant_id ||
          (this.validateVariant(item, false) && this.validatePackage(item, false))
        ) {
          if (item.item_variant_id) {
            for (let key in item) {
              item[key] = this.editedVariant[key];
            }
            this.editedVariant = null;
            expand(!isExpanded);
          } else {
            if (
              this.validateVariant(this.editedVariant, false) &&
              this.validatePackage(this.editedVariant, false)
            ) {
              for (let key in item) {
                item[key] = this.editedVariant[key];
              }
              this.editedVariant = null;
              expand(!isExpanded);
            } else {
              this.expanded = [];
              this.removeVariant(item, false);
            }
          }
        } else {
          this.expanded = [];
          this.removeVariant(item, false);
        }
      },
      savePackage: function () {
        /* empty */
      },
      cancelPackage: function () {
        /* empty */
      },
      openPackage: function () {
        this.$nextTick(() => {
          this.$refs.formVariant.validate();
        });
      },
      closePackage: function () {
        /* empty */
      },
      editItem: function () {
        if (this.expanded.length) {
          if (this.$refs.formName) {
            this.$refs.formName.validate();
          }

          if (this.$refs.formVariant) {
            this.$refs.formVariant.validate();
          }

          return this.$emit('message', {
            content: 'Skończ edytować przedmioty, zanim zapiszesz całość',
            type: 'error',
          });
        }

        // TODO: tu maja byc wszystkie itemy
        this.$emit('edit', this.editedItems);
      },
      closeDialog: function () {
        this.$emit('deactive');
      },
    },
    mounted() {
      let tmp = [
        {
          item_id: null,
          name: '',
          category_name: '',
          item_variant_id: null,
          variant_name: '',
          SKU: '',
          color: '',
          packages: [
            {
              width: '',
              height: '',
              depth: '',
              dimensions_unit: 'cm',
              weight: '',
              measure_unit: 'kg',
              price: '',
              index: 1,
            },
          ],
          index: 1,
        },
      ];

      if (this.items && this.items.length) {
        tmp = JSON.parse(JSON.stringify(this.items));
      }

      for (let i = 0; i < tmp.length; i++) {
        for (let j = 0; j < tmp[i].packages.length; j++) {
          if (tmp[i].packages[j].price == null) {
            tmp[i].packages[j].price = '';
          }

          tmp[i].packages[j].index = j + 1;
        }

        tmp[i].index = i + 1;
      }

      this.editedItems = tmp;
      if (!this.items || !this.items.length) {
        this.expanded = [tmp.variants[0]];
        this.editedVariant = JSON.parse(JSON.stringify(tmp[0]));
      }

      this.counterVariant = this.editedItems.length + 1;
    },
  };
</script>

<style scoped>
  .v-btn--outlined {
    border: thin solid #ffc400 !important;
  }
  .v-btn--pretend--disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
</style>
<style>
  table > tbody > tr.v-data-table__expanded.v-data-table__expanded__row {
    background: #eeeeee !important;
  }
</style>
