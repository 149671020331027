<template>
  <v-dialog v-model="isActive" persistent max-width="600px">
    <v-card>
      <v-toolbar flat color="black" :dark="true">
        <v-toolbar-title>Edytuj dane kierowcy</v-toolbar-title>
        <v-spacer />
      </v-toolbar>

      <v-card-text class="py-4">
        <v-row align="center" justify="center">
          <v-col cols="12" class="mt-4">
            <v-autocomplete
              v-model="driverInfo"
              :search-input.sync="query"
              :loading="isLoading"
              :items="driversList"
              label="Wybierz kierowcę lub podaj nr telefonu"
              item-text="label"
              item-value="name"
              return-object
            >
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="amber accent-3"
          class="grey--text text--darken-4"
          outlined
          :x-large="true"
          v-tilt
          @click="close"
        >
          Anuluj
        </v-btn>

        <v-spacer />

        <v-btn
          color="amber accent-3"
          class="grey--text text--darken-4"
          x-large
          v-tilt
          @click="save"
        >
          Zatwierdz
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import axios from 'axios';
  export default {
    name: 'EditDriverInfoDialog',
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      info: {
        type: Object,
        default: () => {},
      },
    },
    data: () => ({
      driverInfo: {
        phone: '',
        name: '',
        label: false
      },
      query: null,
      isLoading: false,
      pureDriversList: []
    }),
    watch: {
      isActive() {
        this.reset();
      },
      async query(val){
        if(!val || val === this.driverInfo.label) return;
        if (this.isLoading) return;
        this.isLoading = true;

        const response = await axios.get(this.$store.state.url + '/api/drivers/get?query=' + val, {
          headers: { Authorization: `Bearer ${this.$store.state.token}` },
        });

        const { data: { drivers } } = response;

        this.$set(this, 'pureDriversList', drivers.length ? drivers : [
          {
            name: 'Kierowca spoza bazy danych',
            phone: val
          }
        ]);

        this.isLoading = false;
      }
    },
    computed: {
      isActive: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
      driversList() {
        return this.pureDriversList.map(el => {
          Object.assign(el, {
            label: `${ el.name } (${ el.phone })`
          });
          return el;
        });
      }
    },
    methods: {
      close() {
        this.$emit('input', false);
      },
      save() {
        this.$emit('save', this.driverInfo);
      },
      reset() {
        const { info } = this;
        if( info && info.phone && info.name ){
          this.$set(this, 'driverInfo', info);
          this.pureDriversList.push(info)
        }
      },
    },
  };
</script>

<style scoped>
  .v-btn--outlined {
    border: thin solid #ffc400 !important;
  }
</style>
