<template>
  <div>
    <v-progress-circular v-show="loading" indeterminate size="64"></v-progress-circular>
    <v-card v-show="!loading" class="mx-auto" max-width="800" outlined>
      <!-- <v-card-text>
        <v-col cols="12" sm="12" md="12" class="pa-0">
          <v-alert v-if="message" type="error">
            {{ message }}
          </v-alert>
        </v-col>
        <v-toolbar color="black" light  flat @click.prevent.stop="editTransport = !editTransport">
          <v-toolbar-title class="white--text">Transport</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-form v-model="isValidTransport" lazy="true" v-if="editTransport" class="mt-4">
          <v-col cols="12" sm="12" md="12" class="pb-0">
            <v-text-field v-model="trans.expected" label="Szacowana data dostawy" type="date"></v-text-field>
          </v-col>
          <div v-if="trans.type_0">
            <v-row class="mx-auto">
              <v-col cols="12" sm="4" md="4">
                <v-text-field v-model="trans.params[0].volume" label="Łączna objętość" type="number" suffix="m³" :rules="floatRule"></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field v-model="trans.params[0].weight" label="Łączna waga" type="number" suffix="kg" :rules="floatRule"></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field v-model="count" label="Liczba paczek" type="number" suffix="szt" min=1 :rules="positiveInteger"></v-text-field>
              </v-col>
            </v-row>
          </div>
          <div v-else>
            <v-row
              v-for="(item, index) in transport.params"
              :key="index"
              class="mx-auto"
            >
              <v-col v-if="transport.params.length > 1" cols="12" sm="12" md="12" class="pb-0">
                <strong ><br>Paczka {{ index+1 }}:</strong>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-text-field v-model="trans.params[index].width" label="Width (cm)" type="number" min=1 :rules="positiveInteger"></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-text-field v-model="trans.params[index].height" label="Height (cm)" type="number" min=1 :rules="positiveInteger"></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-text-field v-model="trans.params[index].depth" label="Depth (cm)" type="number" min=1 :rules="positiveInteger"></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-text-field v-model="trans.params[index].weight" label="Weight (kg)" type="number" min=1 :rules="positiveInteger"></v-text-field>
              </v-col>
            </v-row>
          </div>

          <v-col cols="12" sm="12" md="12" class="pb-0">
            <v-text-field v-model="trans.content" label="Zawartość" type="text"></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12" class="pb-0">
            <v-text-field v-model="trans.info" label="Dodatkowe informacje" type="text"></v-text-field>
          </v-col>
        </v-form>
      </v-card-text> -->

      <v-card-text>
        <v-col cols="12" sm="12" md="12" class="pa-0">
          <v-alert v-if="message" type="error">
            {{ message }}
          </v-alert>
        </v-col>
        <v-toolbar color="black" light flat @click="editSender = !editSender">
          <v-toolbar-title class="white--text">Nadanie</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-form v-model="isValidSender" lazy="true" v-show="editSender" class="mt-4">
          <v-col cols="12" sm="12" md="12" class="pb-0" v-if="!toggle_one">
            <v-text-field
              v-model="trans.pickup_date"
              label="Termin dostarczenia towaru"
              type="date"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="12" md="12">
            <v-btn-toggle v-model="toggle_one" mandatory rounded>
              <v-btn :value="false"> W siedzibie </v-btn>
              <v-btn :value="true"> Inny adres </v-btn>
            </v-btn-toggle>
          </v-col>

          <v-container v-show="toggle_one">
            <v-col cols="12" sm="12" md="12" class="pb-0">
              <v-text-field
                v-model="trans.pickup_date"
                label="Termin dostarczenia towaru"
                type="date"
              ></v-text-field>
            </v-col>

            <v-row class="mx-auto">
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="send.name"
                  label="Nazwa"
                  type="text"
                  :rules="nameRule"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="mx-auto">
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  v-mask="'##-###'"
                  v-model="send.postcode"
                  label="Kod pocztowy"
                  type="text"
                  :rules="postcodeRule"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="9" md="9">
                <v-text-field
                  v-model="send.city"
                  label="Miasto"
                  :rules="rulesContent"
                  type="text"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="mx-auto">
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="send.street"
                  label="Ulica"
                  :rules="rulesContent"
                  type="text"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <vue-tel-input
                  ref="senderPhone"
                  class="mb-2 mt-2"
                  v-model="send.phone"
                  autoFormat
                  defaultCountry="PL"
                  :allCountries="avaliableCountries"
                  :inputOptions="inputOptions"
                  :dropdownOptions="dropdownOptions"
                  validCharactersOnly
                  @input="(v, o) => validPhone('sender', v, o)"
                  @focus="validSenderPhone.focused_ones = true"
                ></vue-tel-input>
                <div class="v-text-field__details">
                  <div class="v-messages theme--light error--text" role="alert">
                    <div class="v-messages__wrapper">
                      <div class="v-messages__message">
                        {{ validSenderPhone.focused_ones ? validSenderPhone.text : '' }}
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-text>
        <v-toolbar color="black" light flat @click="editReceiver = !editReceiver">
          <v-toolbar-title class="white--text">Dostawa</v-toolbar-title>
          <v-spacer />
        </v-toolbar>

        <v-form v-model="isValidReceiver" lazy="true" v-show="editReceiver" class="mt-4">
          <v-row class="mx-auto">
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="rec.name"
                label="Nazwa"
                type="text"
                :rules="nameRule"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="mx-auto">
            <v-col cols="12" sm="3" md="3">
              <v-text-field
                v-mask="'##-###'"
                v-model="rec.postcode"
                label="Kod pocztowy"
                type="text"
                :rules="postcodeRule"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="9" md="9">
              <v-text-field
                v-model="rec.city"
                label="Miasto"
                :rules="rulesContent"
                type="text"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="mx-auto">
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="rec.street"
                label="Ulica"
                :rules="rulesContent"
                type="text"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <vue-tel-input
                ref="receiverPhone"
                class="mb-2 mt-2"
                v-model="rec.phone"
                autoFormat
                defaultCountry="PL"
                :allCountries="avaliableCountries"
                :inputOptions="inputOptions"
                :dropdownOptions="dropdownOptions"
                validCharactersOnly
                @input="(v, o) => validPhone('receiver', v, o)"
                @focus="validReceiverPhone.focused_ones = true"
              ></vue-tel-input>
              <div class="v-text-field__details">
                <div class="v-messages theme--light error--text" role="alert">
                  <div class="v-messages__wrapper">
                    <div class="v-messages__message">
                      {{ validReceiverPhone.focused_ones ? validReceiverPhone.text : '' }}
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions align="right" justify="center">
        <v-col cols="12" sm="12" md="12">
          <v-btn
            color="amber accent-3"
            class="grey--text text--darken-4"
            :x-large="true"
            v-tilt
            :disabled="!validForms || prvBtn"
            @click="changeAllData"
          >
            Zmień dane
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
  import axios from 'axios';
  import { mask } from 'vue-the-mask';
  import { VueTelInput } from 'vue-tel-input';

  export default {
    name: 'TransportEditForm',
    props: {
      transport: Object,
      sender: Object,
      receiver: Object,
    },
    components: {
      VueTelInput,
    },
    directives: {
      mask,
    },
    data: () => ({
      loading: true,
      toggle_one: false,
      trans: {},
      rec: {},
      send: {},
      getStatus: null,
      message: null,
      valid: true,
      editTransport: false,
      editSender: false,
      editReceiver: false,
      isValidTransport: null,
      isValidSender: null,
      isValidReceiver: null,
      rulesContent: [v => !!v || 'Pole jest wymagane'],
      nameRule: [
        v => !!v || 'Pole jest wymagane',
        v =>
          /^[a-zA-Z_\ -zżźćńółęąśŻŹĆĄŚĘŁÓŃ]{3,100}$/.test(v) ||
          'Nazwa ma niepoprawny format (mininimum 3, a maksymalnie 50 znaków)',
      ],
      positiveInteger: [
        v => !!v || 'Pole jest wymagane',
        v => /^[1-9]+[0-9]*$/.test(v) || 'Liczba naturalna, bez zera oraz poprzedzających zer',
      ],
      postcodeRule: [
        v => !!v || 'Pole jest wymagane',
        v => /^[0-9]{2}-[0-9]{3}$/.test(v) || 'Kod pocztowy ma niewłaściwy format',
      ],
      phoneRule: [
        v => !!v || 'Pole jest wymagane',
        v =>
          /^(\+[0-9]{2}[-. ]?)?[0-9]{3}[-. ]?[0-9]{3}[-. ]?[0-9]{3}$/.test(v) ||
          'Wprowadź poprawny nr telefonu',
      ],
      floatRule: [
        v => !!v || 'Pole jest wymagane',
        v =>
          /^([1-9]\d*(\.|,)\d{1,2}|0(\.|,)\d{1,2}|[1-9]\d*|0)$/.test(v) ||
          'Liczba ma niepoprawny format lub jest ujemna',
      ],
      count: null,
      prvBtn: false,

      inputOptions: {
        autocomplete: 'on',
        autofocus: false,
        id: null,
        maxlength: 25,
        name: 'telephone',
        placeholder: 'Numer telefonu',
        readonly: false,
        required: true,
        tabindex: 0,
        type: 'tel',
        styleClasses: '',
      },
      dropdownOptions: {
        showDialCodeInSelection: true,
        showDialCodeInList: true,
        showFlags: true,
        tabindex: 0,
      },
      avaliableCountries: [
        ['Albania (Shqipëri)', 'al', '355'],
        ['Andorra', 'ad', '376'],
        ['Armenia (Հայաստան)', 'am', '374'],
        ['Austria (Österreich)', 'at', '43'],
        ['Azerbaijan (Azərbaycan)', 'az', '994'],
        ['Belarus (Беларусь)', 'by', '375'],
        ['Belgium (België)', 'be', '32'],
        ['Bosnia and Herzegovina (Босна и Херцеговина)', 'ba', '387'],
        ['Bulgaria (България)', 'bg', '359'],
        ['Croatia (Hrvatska)', 'hr', '385'],
        ['Cyprus (Κύπρος)', 'cy', '357'],
        ['Czech Republic (Česká republika)', 'cz', '420'],
        ['Denmark (Danmark)', 'dk', '45'],
        ['Estonia (Eesti)', 'ee', '372'],
        ['Finland (Suomi)', 'fi', '358'],
        ['France', 'fr', '33'],
        ['Georgia (საქართველო)', 'ge', '995'],
        ['Germany (Deutschland)', 'de', '49'],
        ['Greece (Ελλάδα)', 'gr', '30'],
        ['Hungary (Magyarország)', 'hu', '36'],
        ['Iceland (Ísland)', 'is', '354'],
        ['Ireland', 'ie', '353'],
        ['Italy (Italia)', 'it', '39'],
        ['Kazakhstan (Казахстан)', 'kz', '7'],
        ['Kosovo', 'xk', '383'],
        ['Latvia (Latvija)', 'lv', '371'],
        ['Liechtenstein', 'li', '423'],
        ['Lithuania (Lietuva)', 'lt', '370'],
        ['Luxembourg', 'lu', '352'],
        ['Malta', 'mt', '356'],
        ['Moldova (Republica Moldova)', 'md', '373'],
        ['Monaco', 'mc', '377'],
        ['Montenegro (Crna Gora)', 'me', '382'],
        ['Netherlands (Nederland)', 'nl', '31'],
        ['Macedonia (FYROM) (Македонија)', 'mk', '389'],
        ['Norway (Norge)', 'no', '47'],
        ['Poland (Polska)', 'pl', '48'],
        ['Portugal', 'pt', '351'],
        ['Romania (România)', 'ro', '40'],
        ['Russia (Россия)', 'ru', '7'],
        ['San Marino', 'sm', '378'],
        ['Serbia (Србија)', 'rs', '381'],
        ['Slovakia (Slovensko)', 'sk', '421'],
        ['Slovenia (Slovenija)', 'si', '386'],
        ['Spain (España)', 'es', '34'],
        ['Sweden (Sverige)', 'se', '46'],
        ['Switzerland (Schweiz)', 'ch', '41'],
        ['Turkey (Türkiye)', 'tr', '90'],
        ['Ukraine (Україна)', 'ua', '380'],
        ['United Kingdom', 'gb', '44'],
        ['Vatican City (Città del Vaticano)', 'va', '39'],

        ['United States', 'us', '1'],
        ['Canada', 'ca', '1'],
        ['Australia', 'au', '61'],
        ['Japan (日本)', 'jp', '81'],
        ['China (中国)', 'cn', '86'],
        ['South Korea (대한민국)', 'kr', '82'],
      ].map(([name, iso2, dialCode]) => ({
        name,
        iso2: iso2.toUpperCase(),
        dialCode,
      })),
      validReceiverPhone: {
        focused_ones: false,
        valid: true,
        text: '',
        dialCode: '48',
      },
      validSenderPhone: {
        focused_ones: false,
        valid: true,
        text: '',
        dialCode: '48',
      },
    }),
    watch: {
      toggle_one: function (val) {
        if (!val) {
          this.validSenderPhone.focused_ones = false;
          this.validSenderPhone.valid = true;
          this.validSenderPhone.text = '';
        }
      },
    },
    methods: {
      validPhone: function (field = 'receiver', number, phoneObject) {
        let tmp = field == 'receiver' ? this.validReceiverPhone : this.validSenderPhone;
        if (number.length) {
          if (phoneObject.valid) {
            tmp.valid = true;
            tmp.text = '';
          } else {
            tmp.valid = false;
            tmp.text = 'Numer ma niepoprawny format';
          }
        } else {
          tmp.valid = false;
          tmp.text = 'Pole jest wymagane';
        }
      },
      changeAllData: function () {
        let that = this;
        this.prvBtn = true;
        if (
          this.trans.expected != null &&
          new Date(this.trans.expected) < new Date(this.trans.pickup_date)
        ) {
          this.message = 'Szacowany czas dostawy nie może być przed datą nadania';
          setTimeout(function () {
            that.prvBtn = false;
          }, 1000);
          return (document.getElementsByClassName('v-dialog v-dialog--active')[0].scrollTop = 0);
        }

        let tmp = {
          token: this.$store.state.token,
          id: this.$route.params.id,
          transport: null,
          receiver: null,
          sender: null,
          sender_toggle: this.toggle_one,
        };
        if (this.editTransport) {
          tmp.transport = this.trans;
        }
        if (this.editReceiver) {
          tmp.receiver = this.rec;
          tmp.receiver.dialCode = this.validReceiverPhone.dialCode;
        }
        if (this.editSender) {
          tmp.sender = this.send;
          tmp.sender.pickup_date = this.trans.pickup_date;
          tmp.sender.dialCode = this.validSenderPhone.dialCode;
        }
        if (this.trans.type_0 && this.count && this.count > 0) {
          tmp.count = this.count;
        }

        axios({
          url: this.$store.state.url + '/api/transport/modify/data',
          data: tmp,
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status === 200) {
              window.location.reload();
            } else {
              this.message = resp.data.message;
              setTimeout(function () {
                that.prvBtn = false;
              }, 1000);
            }
          })
          .catch(() => {
            setTimeout(function () {
              that.prvBtn = false;
            }, 1000);
          });
      },
    },
    computed: {
      validForms: function () {
        if (!this.editReceiver && !this.editSender) return false;
        if (this.editReceiver)
          if (!this.isValidReceiver || !this.validReceiverPhone.valid) return false;
        if (this.editSender)
          if (this.toggle_one && (!this.isValidSender || !this.validSenderPhone.valid)) return false;

        return true;
      },
    },
    mounted() {
      Object.assign(this.trans, this.transport);
      Object.assign(this.rec, this.receiver);
      Object.assign(this.send, this.sender);
      this.count = this.trans.params.length;

      //this.trans.expected = this.trans.expected.replace(/\./gi, "-")
      if (this.trans.expected) {
        let arr = this.trans.expected.split('.');
        this.trans.expected = arr[2] + '-' + arr[1] + '-' + arr[0];
      } else {
        this.trans.expected = null;
      }

      if (this.trans.pickup_date) {
        let arr = this.trans.pickup_date.split('.');
        this.trans.pickup_date = arr[2] + '-' + arr[1] + '-' + arr[0];
      } else {
        this.trans.pickup_date = null;
      }

      this.toggle_one = this.sender ? true : false;
      setTimeout(() => {
        this.loading = false;
      }, 200);

      if (this.rec.dialCode)
        this.$refs.receiverPhone.activeCountryCode = this.$refs.receiverPhone.allCountries.find(
          el => el.dialCode == this.rec.dialCode
        ).iso2;
      if (this.send.dialCode)
        this.$refs.senderPhone.activeCountryCode = this.$refs.senderPhone.allCountries.find(
          el => el.dialCode == this.send.dialCode
        ).iso2;
    },
    created() {},
  };
</script>

<style src="vue-tel-input/dist/vue-tel-input.css"></style>

