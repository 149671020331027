var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","width":_vm.width},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","color":"black","dark":""}},[_c('v-toolbar-title',[_vm._v(" Edycja przedmiotów ")]),_c('v-spacer')],1),_c('v-card-text',{staticClass:"pt-5 pb-1 grey--text text--darken-4"},[_c('v-form',{ref:"itemForm",model:{value:(_vm.itemForm),callback:function ($$v) {_vm.itemForm=$$v},expression:"itemForm"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.itemsCopy,"expanded":_vm.expanded,"item-key":"index","show-expand":"","single-expand":true,"disable-filtering":"","disable-sort":"","hide-default-footer":"","disable-pagination":"","dense":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.item_type",fn:function(ref){
var item = ref.item;
return [_c('v-select',{staticClass:"no-padding-item",attrs:{"items":_vm.item_types,"hide-details":"","item-text":_vm.itemTypeMapper,"item-value":"item_type","dense":"","flat":"","solo":""},on:{"change":function($event){return _vm.editItemType($event, item)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.itemTypeMapper(item))+" ")])]}},{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-pencil mdi-18px ")])]},proxy:true}],null,true),model:{value:(item.item_type),callback:function ($$v) {_vm.$set(item, "item_type", $$v)},expression:"item.item_type"}})]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.quantity},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"single-line":"","label":"Ilość","rules":_vm.rules.quantity,"type":"number","max":"9999","min":"0","dense":"","autofocus":""},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.quantity)+" "),_c('v-btn',{attrs:{"icon":"","color":"primary","x-small":""}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.name},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"single-line":"","label":"Nazwa","rules":_vm.rules.notEmpty,"type":"text","maxlength":"128","dense":"","autofocus":""},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.name)+" "),_c('v-btn',{attrs:{"icon":"","color":"primary","x-small":""}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)],1)]}},{key:"item.variant_name",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.variant_name},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"single-line":"","label":"Wariant","rules":_vm.rules.notEmpty,"type":"text","maxlength":"128","dense":"","autofocus":""},model:{value:(item.variant_name),callback:function ($$v) {_vm.$set(item, "variant_name", $$v)},expression:"item.variant_name"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.variant_name)+" "),_c('v-btn',{attrs:{"icon":"","color":"primary","x-small":""}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)],1)]}},{key:"item.SKU",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_c('v-edit-dialog',{attrs:{"return-value":item.SKU},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"single-line":"","label":"SKU","type":"text","maxlength":"32","dense":"","autofocus":""},model:{value:(item.SKU),callback:function ($$v) {_vm.$set(item, "SKU", $$v)},expression:"item.SKU"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.SKU)+" "),_c('v-btn',{attrs:{"icon":"","color":"primary","x-small":""}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)],1)],1)]}},{key:"item.color",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_c('v-edit-dialog',{attrs:{"return-value":item.color},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"single-line":"","label":"Wybarwienie","type":"text","maxlength":"32","dense":"","autofocus":""},model:{value:(item.color),callback:function ($$v) {_vm.$set(item, "color", $$v)},expression:"item.color"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.color)+" "),_c('v-btn',{attrs:{"icon":"","color":"primary","x-small":""}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)],1)],1)]}},{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [_c('v-btn',{attrs:{"icon":"","small":"","color":"primary"},on:{"click":function($event){return expand(!isExpanded)}}},[_c('v-icon',[_vm._v(" "+_vm._s(("mdi-chevron-" + (isExpanded ? 'up' : 'down')))+" ")])],1),_c('v-btn',{attrs:{"icon":"","color":"error","small":""},on:{"click":function($event){return _vm.removeItem(item)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(_vm.isMobile)?_c('td',{staticClass:"pa-1",staticStyle:{"background":"#fff"}}):_vm._e(),_c('td',{staticClass:"ma-n1 pa-0",style:(_vm.isMobile ? 'width: 200%; min-width: 200%' : ''),attrs:{"colspan":headers.length}},[_c('v-data-table',{staticStyle:{"padding-left":"5%","border-radius":"0"},attrs:{"headers":_vm.packageHeaders(item.item_type),"items":item.packages,"disable-filtering":"","disable-sort":"","hide-default-footer":"","disable-pagination":"","dense":"","no-data-text":"Brak przypisanych przedmiotów","items-per-page":-1},scopedSlots:_vm._u([{key:"item.width",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.width},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"single-line":"","label":"Szerokość","rules":_vm.rules.float_9_5,"type":"number","maxlength":"14","min":"0","dense":"","autofocus":""},model:{value:(item.width),callback:function ($$v) {_vm.$set(item, "width", $$v)},expression:"item.width"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.width)+" "),_c('v-btn',{attrs:{"icon":"","color":"primary","x-small":""}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)],1)]}},{key:"item.height",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.height},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"single-line":"","label":"Długość","rules":_vm.rules.float_9_5,"type":"number","maxlength":"14","min":"0","dense":"","autofocus":""},model:{value:(item.height),callback:function ($$v) {_vm.$set(item, "height", $$v)},expression:"item.height"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.height)+" "),_c('v-btn',{attrs:{"icon":"","color":"primary","x-small":""}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)],1)]}},{key:"item.depth",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.depth},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"single-line":"","label":"Wysokość","rules":_vm.rules.float_9_5,"type":"number","maxlength":"14","min":"0","dense":"","autofocus":""},model:{value:(item.depth),callback:function ($$v) {_vm.$set(item, "depth", $$v)},expression:"item.depth"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.depth)+" "),_c('v-btn',{attrs:{"icon":"","color":"primary","x-small":""}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)],1)]}},{key:"item.dimensions_unit",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.dimensions_unit},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-select',{attrs:{"items":_vm.dimensions_unit,"dense":""},model:{value:(item.dimensions_unit),callback:function ($$v) {_vm.$set(item, "dimensions_unit", $$v)},expression:"item.dimensions_unit"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.dimensions_unit)+" ")])]}},{key:"item.weight",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.weight},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"single-line":"","label":"Waga","rules":_vm.rules.float_9_5,"type":"number","maxlength":"14","min":"0","dense":"","autofocus":""},model:{value:(item.weight),callback:function ($$v) {_vm.$set(item, "weight", $$v)},expression:"item.weight"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.weight)+" "),_c('v-btn',{attrs:{"icon":"","color":"primary","x-small":""}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)],1)]}},{key:"item.measure_unit",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.measure_unit},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-select',{attrs:{"items":_vm.measure_unit,"dense":""},model:{value:(item.measure_unit),callback:function ($$v) {_vm.$set(item, "measure_unit", $$v)},expression:"item.measure_unit"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.measure_unit)+" ")])]}},{key:"item.cardboard_boxes",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.cardboard_boxes},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-select',{attrs:{"item-value":"value","item-text":"text","items":[
                          { value: true, text: 'TAK' },
                          { value: false, text: 'NIE' } ],"dense":""},model:{value:(item.cardboard_boxes),callback:function ($$v) {_vm.$set(item, "cardboard_boxes", $$v)},expression:"item.cardboard_boxes"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.cardboard_boxes ? 'TAK' : 'NIE')+" ")])]}},{key:"item.actions",fn:function(ref){
                        var pck = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"error","x-small":""},on:{"click":function($event){return _vm.removePackage(item, pck)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}],null,true)}),_c('v-row',{staticClass:"my-0 py-2 white",attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-btn',{staticClass:"grey--text text--darken-4 mx-auto",attrs:{"icon":"","color":"blue-grey","small":""},on:{"click":function($event){return _vm.addPackage(item)}}},[_c('v-icon',[_vm._v(" mdi-plus-circle ")])],1)],1)],1)]}}],null,true)}),_c('v-row',{staticClass:"my-4",attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-btn',{staticClass:"mx-auto",attrs:{"block":"","x-large":"","dark":"","color":"primary"},on:{"click":_vm.addItem}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Dodaj przedmiot ")],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{directives:[{name:"tilt",rawName:"v-tilt"}],staticClass:"grey--text text--darken-4",attrs:{"color":"amber accent-3","outlined":"","x-large":""},on:{"click":function($event){_vm.isActive = false}}},[_vm._v(" Anuluj ")]),_c('v-spacer'),_c('v-btn',{directives:[{name:"tilt",rawName:"v-tilt"}],staticClass:"grey--text text--darken-4",attrs:{"color":"amber accent-3","x-large":""},on:{"click":_vm.submit}},[_vm._v(" Edytuj ")])],1)],1),_c('ConfirmDialog',{ref:"confirmDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }