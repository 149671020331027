<template>
  <v-dialog v-model="isActive" persistent :width="width">
    <v-card>
      <v-toolbar flat color="black" dark>
        <v-toolbar-title> Edycja przedmiotów </v-toolbar-title>
        <v-spacer />
      </v-toolbar>

      <v-card-text class="pt-5 pb-1 grey--text text--darken-4">
        <v-form ref="itemForm" v-model="itemForm">
          <v-data-table
            :headers="headers"
            :items="itemsCopy"
            :expanded.sync="expanded"
            item-key="index"
            show-expand
            :single-expand="true"
            disable-filtering
            disable-sort
            hide-default-footer
            disable-pagination
            dense
            class="elevation-1"
          >
            <template v-slot:[`item.item_type`]="{ item }">
              <v-select
                v-model="item.item_type"
                :items="item_types"
                hide-details
                :item-text="itemTypeMapper"
                item-value="item_type"
                dense
                flat
                solo
                class="no-padding-item"
                @change="editItemType($event, item)"
              >
                <template v-slot:selection="{ item }">
                  <span> {{ itemTypeMapper(item) }} </span>
                </template>

                <template v-slot:append>
                  <v-icon color="primary"> mdi-pencil mdi-18px </v-icon>
                </template>
              </v-select>
            </template>

            <template v-slot:[`item.quantity`]="{ item }">
              <v-edit-dialog :return-value="item.quantity">
                {{ item.quantity }}

                <v-btn icon color="primary" x-small>
                  <v-icon> mdi-pencil </v-icon>
                </v-btn>

                <template v-slot:input>
                  <v-text-field
                    v-model="item.quantity"
                    single-line
                    label="Ilość"
                    :rules="rules.quantity"
                    type="number"
                    max="9999"
                    min="0"
                    dense
                    autofocus
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>

            <template v-slot:[`item.name`]="{ item }">
              <v-edit-dialog :return-value="item.name">
                {{ item.name }}

                <v-btn icon color="primary" x-small>
                  <v-icon> mdi-pencil </v-icon>
                </v-btn>

                <template v-slot:input>
                  <v-text-field
                    v-model="item.name"
                    single-line
                    label="Nazwa"
                    :rules="rules.notEmpty"
                    type="text"
                    maxlength="128"
                    dense
                    autofocus
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>

            <template v-slot:[`item.variant_name`]="{ item }">
              <v-edit-dialog :return-value="item.variant_name">
                {{ item.variant_name }}

                <v-btn icon color="primary" x-small>
                  <v-icon> mdi-pencil </v-icon>
                </v-btn>

                <template v-slot:input>
                  <v-text-field
                    v-model="item.variant_name"
                    single-line
                    label="Wariant"
                    :rules="rules.notEmpty"
                    type="text"
                    maxlength="128"
                    dense
                    autofocus
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>

            <template v-slot:[`item.SKU`]="{ item }">
              <div class="text-no-wrap">
                <v-edit-dialog :return-value="item.SKU">
                  {{ item.SKU }}

                  <v-btn icon color="primary" x-small>
                    <v-icon> mdi-pencil </v-icon>
                  </v-btn>

                  <template v-slot:input>
                    <v-text-field
                      v-model="item.SKU"
                      single-line
                      label="SKU"
                      type="text"
                      maxlength="32"
                      dense
                      autofocus
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </div>
            </template>

            <template v-slot:[`item.color`]="{ item }">
              <div class="text-no-wrap">
                <v-edit-dialog :return-value="item.color">
                  {{ item.color }}

                  <v-btn icon color="primary" x-small>
                    <v-icon> mdi-pencil </v-icon>
                  </v-btn>

                  <template v-slot:input>
                    <v-text-field
                      v-model="item.color"
                      single-line
                      label="Wybarwienie"
                      type="text"
                      maxlength="32"
                      dense
                      autofocus
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </div>
            </template>

            <template v-slot:[`item.data-table-expand`]="{ item, expand, isExpanded }">
              <v-btn icon small color="primary" @click="expand(!isExpanded)">
                <v-icon> {{ `mdi-chevron-${isExpanded ? 'up' : 'down'}` }} </v-icon>
              </v-btn>

              <v-btn icon color="error" small @click="removeItem(item)">
                <v-icon> mdi-delete </v-icon>
              </v-btn>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td v-if="isMobile" class="pa-1" style="background: #fff"></td>
              <td
                :colspan="headers.length"
                class="ma-n1 pa-0"
                :style="isMobile ? 'width: 200%; min-width: 200%' : ''"
              >
                <v-data-table
                  :headers="packageHeaders(item.item_type)"
                  :items="item.packages"
                  disable-filtering
                  disable-sort
                  hide-default-footer
                  disable-pagination
                  dense
                  no-data-text="Brak przypisanych przedmiotów"
                  :items-per-page="-1"
                  style="padding-left: 5%; border-radius: 0"
                >
                  <template v-slot:[`item.width`]="{ item }">
                    <v-edit-dialog :return-value="item.width">
                      {{ item.width }}

                      <v-btn icon color="primary" x-small>
                        <v-icon> mdi-pencil </v-icon>
                      </v-btn>

                      <template v-slot:input>
                        <v-text-field
                          v-model="item.width"
                          single-line
                          label="Szerokość"
                          :rules="rules.float_9_5"
                          type="number"
                          maxlength="14"
                          min="0"
                          dense
                          autofocus
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>

                  <template v-slot:[`item.height`]="{ item }">
                    <v-edit-dialog :return-value="item.height">
                      {{ item.height }}

                      <v-btn icon color="primary" x-small>
                        <v-icon> mdi-pencil </v-icon>
                      </v-btn>

                      <template v-slot:input>
                        <v-text-field
                          v-model="item.height"
                          single-line
                          label="Długość"
                          :rules="rules.float_9_5"
                          type="number"
                          maxlength="14"
                          min="0"
                          dense
                          autofocus
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>

                  <template v-slot:[`item.depth`]="{ item }">
                    <v-edit-dialog :return-value="item.depth">
                      {{ item.depth }}

                      <v-btn icon color="primary" x-small>
                        <v-icon> mdi-pencil </v-icon>
                      </v-btn>

                      <template v-slot:input>
                        <v-text-field
                          v-model="item.depth"
                          single-line
                          label="Wysokość"
                          :rules="rules.float_9_5"
                          type="number"
                          maxlength="14"
                          min="0"
                          dense
                          autofocus
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>

                  <template v-slot:[`item.dimensions_unit`]="{ item }">
                    <v-edit-dialog :return-value="item.dimensions_unit">
                      {{ item.dimensions_unit }}

                      <template v-slot:input>
                        <v-select
                          v-model="item.dimensions_unit"
                          :items="dimensions_unit"
                          dense
                        ></v-select>
                      </template>
                    </v-edit-dialog>
                  </template>

                  <template v-slot:[`item.weight`]="{ item }">
                    <v-edit-dialog :return-value="item.weight">
                      {{ item.weight }}

                      <v-btn icon color="primary" x-small>
                        <v-icon> mdi-pencil </v-icon>
                      </v-btn>

                      <template v-slot:input>
                        <v-text-field
                          v-model="item.weight"
                          single-line
                          label="Waga"
                          :rules="rules.float_9_5"
                          type="number"
                          maxlength="14"
                          min="0"
                          dense
                          autofocus
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>

                  <template v-slot:[`item.measure_unit`]="{ item }">
                    <v-edit-dialog :return-value="item.measure_unit">
                      {{ item.measure_unit }}

                      <template v-slot:input>
                        <v-select
                          v-model="item.measure_unit"
                          :items="measure_unit"
                          dense
                        ></v-select>
                      </template>
                    </v-edit-dialog>
                  </template>

                  <template v-slot:[`item.cardboard_boxes`]="{ item }">
                    <v-edit-dialog :return-value="item.cardboard_boxes">
                      {{ item.cardboard_boxes ? 'TAK' : 'NIE' }}

                      <template v-slot:input>
                        <v-select
                          v-model="item.cardboard_boxes"
                          item-value="value"
                          item-text="text"
                          :items="[
                            { value: true, text: 'TAK' },
                            { value: false, text: 'NIE' },
                          ]"
                          dense
                        ></v-select>
                      </template>
                    </v-edit-dialog>
                  </template>

                  <template v-slot:[`item.actions`]="{ item: pck }">
                    <v-btn icon color="error" x-small @click="removePackage(item, pck)">
                      <v-icon> mdi-delete </v-icon>
                    </v-btn>
                  </template>
                </v-data-table>

                <v-row justify="center" align="center" no-gutters class="my-0 py-2 white">
                  <v-btn
                    icon
                    color="blue-grey"
                    class="grey--text text--darken-4 mx-auto"
                    small
                    @click="addPackage(item)"
                  >
                    <v-icon> mdi-plus-circle </v-icon>
                  </v-btn>
                </v-row>
              </td>
            </template>
          </v-data-table>

          <v-row justify="center" align="center" no-gutters class="my-4">
            <v-btn block x-large dark color="primary" class="mx-auto" @click="addItem">
              <v-icon left> mdi-plus </v-icon> Dodaj przedmiot
            </v-btn>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="amber accent-3"
          class="grey--text text--darken-4"
          outlined
          v-tilt
          x-large
          @click="isActive = false"
        >
          Anuluj
        </v-btn>

        <v-spacer />

        <v-btn
          color="amber accent-3"
          class="grey--text text--darken-4"
          v-tilt
          x-large
          @click="submit"
        >
          Edytuj
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- confirm dialog -->
    <ConfirmDialog ref="confirmDialog"></ConfirmDialog>
  </v-dialog>
</template>

<script>
  import { v4 as uuidv4 } from 'uuid';
  import ConfirmDialog from './Confirm.vue';

  export default {
    name: 'EditTransportItemsDialog',
    components: {
      ConfirmDialog,
    },
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      items: {
        type: Array,
        required: true,
      },
      width: {
        type: String,
        default: '820px',
      },
    },
    data: () => ({
      itemForm: false,

      itemsCopy: [],
      item_types: [
        {
          item_type: 'hard_furniture',
          name: 'Meble w paczkach',
        },
        {
          item_type: 'upholstered_furniture',
          name: 'Meble tapicerowane',
        },
        {
          item_type: 'chairs',
          name: 'Krzesła',
        },
        {
          item_type: 'tables',
          name: 'Stoły',
        },
      ],

      dimensions_unit: ['m', 'dm', 'cm'],
      measure_unit: ['kg'], // ['kg', 'g'],
      package_unit: ['szt'],

      expanded: [],
      headers: [
        {
          text: 'Typ',
          value: 'item_type',
          width: '128px',
        },
        {
          text: 'Ilość',
          value: 'quantity',
          width: '78px',
        },
        {
          text: 'Nazwa',
          value: 'name',
          width: '35%',
        },
        {
          text: 'Wariant',
          value: 'variant_name',
          width: '35%',
        },
        {
          text: 'SKU',
          value: 'SKU',
          width: '15%',
        },
        {
          text: 'Wybarwienie',
          value: 'color',
          width: '15%',
        },
        {
          text: '',
          value: 'data-table-expand',
          width: '72px',
        },
      ],

      rules: {
        notEmpty: [v => !!v || 'Pole jest wymagane'],
        float_9_5: [
          v => !!v || 'Pole jest wymagane',
          v => /^(-?(0|([1-9][0-9]{0,8}))((\.|,)[0-9]{0,4}[1-9])?)$/.test(v) || 'Niewłaściwa liczba',
          v => v >= 0 || 'Liczba ujemna',
          v => v != 0 || 'Różne od zera',
        ],
        quantity: [
          v => !!v || 'Pole jest wymagane',
          v => /^[1-9][0-9]{0,3}$/.test(v) || 'Wybierz z przedziału 1-9999',
        ],
      },
    }),
    computed: {
      isActive: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
      isAdmin: function () {
        return this.$store.getters.isAdmin;
      },
      isMobile: function () {
        return this.$vuetify.breakpoint.xs;
      },
    },
    watch: {
      value: function (val) {
        if (val) this.itemsCopy = JSON.parse(JSON.stringify(this.items));
        else this.expanded = [];
      },
    },
    methods: {
      infoSnackBar(info) {
        this.$emit('info', {
          message: info.message || '',
          status: info.status || 'error',
          timeout: info.timeout,
        });
      },
      itemTypeMapper: function (itemType) {
        if (typeof itemType === 'object' && typeof itemType.item_type === 'string')
          itemType = itemType.item_type;

        switch (itemType) {
          case 'hard_furniture':
            return 'Meble paczkowane';
          case 'upholstered_furniture':
            return 'Meble twarde';
          case 'chairs':
            return 'Krzesła';
          case 'tables':
            return 'Stoły';
          default:
            return null;
        }
      },
      packageHeaders: function (item_type) {
        const tmp = [
          {
            text: 'LP',
            value: 'index',
          },
          {
            text: 'Szerokość',
            value: 'width',
          },
          {
            text: 'Długość',
            value: 'height',
          },
          {
            text: 'Wysokość',
            value: 'depth',
          },
          {
            text: 'J. wymiaru',
            value: 'dimensions_unit',
          },
          {
            text: 'Waga',
            value: 'weight',
          },
          {
            text: 'J. wagi',
            value: 'measure_unit',
          },
          {
            text: '',
            value: 'actions',
            width: '42px',
          },
        ];

        if (item_type === 'chairs')
          tmp.splice(7, 0, {
            text: 'Karton',
            value: 'cardboard_boxes',
            width: '54px',
          });

        return tmp;
      },
      floatValidatorGenerator: function (
        to_the_left,
        to_the_right,
        required = true,
        positive = true,
        notZero = true
      ) {
        const rules = [];

        let left = '0';
        if (to_the_left > 0) left = `-?(0|([1-9][0-9]{0,${to_the_left - 1}}))`;

        let right = '';
        if (to_the_right > 0) right = `((\\.|,)[0-9]{0,${to_the_right - 1}}[1-9])?`;

        if (required) {
          rules.push(v => !!v || 'Pole jest wymagane');
          rules.push(v => new RegExp(`^(${left + right})$`).test(v));
        } else {
          rules.push(v => new RegExp(`^$|^(${left + right})$`).test(v));
        }

        if (positive) rules.push(v => v >= 0);

        if (notZero) {
          if (required) rules.push(v => v != 0);
          else rules.push(v => v == '' || v != 0);
        }

        return function (num) {
          for (let index = 0; index < rules.length; index++) if (!rules[index](num)) return false;
          return true;
        };
      },

      addItem: function () {
        const tmp = {
          index: this.itemsCopy.length + 1,
          quantity: 1,
          item_id: uuidv4(),
          item_type: 'hard_furniture',
          name: '',
          variant_name: '',
          SKU: '',
          color: '',
          packages: [
            {
              index: 1,
              depth: '',
              width: '',
              height: '',
              weight: '',
              package_id: uuidv4(),
              measure_unit: 'kg',
              dimensions_unit: 'cm',
            },
          ],
          price: 0,
          received: false,
        };
        if (tmp.item_type === 'chairs') tmp.cardboard_boxes = true;

        this.itemsCopy.push(tmp);
        this.expanded.push(tmp);
      },
      addPackage: function (item) {
        const tmp = {
          index: item.packages.length + 1,
          depth: '',
          width: '',
          height: '',
          weight: '',
          package_id: uuidv4(),
          measure_unit: 'kg',
          dimensions_unit: 'cm',
        };
        if (item.item_type === 'chairs') tmp.cardboard_boxes = true;

        item.packages.push(tmp);
      },
      editItemType: function (itemType, item) {
        item.packages.forEach(elem => {
          if (itemType === 'chairs') this.$set(elem, 'cardboard_boxes', elem.cardboard_boxes ?? true);
          else delete elem.cardboard_boxes;
        });
      },
      removePackage: async function (item, pck) {
        const confirm = await this.$refs.confirmDialog.open(
          'Usuń paczkę',
          'Czy na pewno chcesz usunąć paczkę?',
          { color: 'red' }
        );
        if (!confirm) return;

        const index = item.packages.indexOf(pck);
        if (index !== -1) item.packages.splice(index, 1);
        if (!item.packages.length) this.removeItem(item, true);
      },
      removeItem: async function (item, confirm = false) {
        if (
          !confirm &&
          !(await this.$refs.confirmDialog.open(
            'Usuń przedmiot',
            'Czy na pewno chcesz usunąć przedmiot?',
            {
              color: 'red',
            }
          ))
        )
          return;

        const itemIndex = this.itemsCopy.indexOf(item);
        if (itemIndex !== -1) this.itemsCopy.splice(itemIndex, 1);

        // fix indexes
        let cnt = 1;
        for (const i of this.itemsCopy) i.index = cnt++;

        const expandedItem = this.expanded.indexOf(item);
        if (expandedItem !== -1) this.expanded.splice(expandedItem, 1);
      },
      submit: function () {
        const float_9_5 = this.floatValidatorGenerator(9, 5, true, true, false);
        for (let i = 0; i < this.itemsCopy.length; i++) {
          const item = this.itemsCopy[i];
          for (let j = 0; j < item.packages.length; j++) {
            const pck = item.packages[j];
            if (!float_9_5(pck.width))
              return this.infoSnackBar({
                message: `Przedmiot ${i + 1} -> Paczka ${j + 1}: Szerokość ma zły format`,
              });
            if (!float_9_5(pck.height))
              return this.infoSnackBar({
                message: `Przedmiot ${i + 1} -> Paczka ${j + 1}: Długość ma zły format`,
              });
            if (!float_9_5(pck.depth))
              return this.infoSnackBar({
                message: `Przedmiot ${i + 1} -> Paczka ${j + 1}: Wysokość ma zły format`,
              });
            if (!float_9_5(pck.weight))
              return this.infoSnackBar({
                message: `Przedmiot ${i + 1} -> Paczka ${j + 1}: Waga ma zły format`,
              });
          }

          if (!item.name)
            return this.infoSnackBar({ message: `Przedmiot ${i + 1}: Wariant nie może być pusty` });
          if (item.name.length > 128)
            return this.infoSnackBar({
              message: `Przedmiot ${i + 1}: Nazwa może zawierać do 128 znaków`,
            });
          if (!item.variant_name)
            return this.infoSnackBar({ message: `Przedmiot ${i + 1}: Wariant nie może być pusty` });
          if (item.variant_name.length > 128)
            return this.infoSnackBar({
              message: `Przedmiot ${i + 1}: Wariant może zawierać do 128 znaków`,
            });
          if (item.SKU && item.SKU.length > 32)
            return this.infoSnackBar({
              message: `Przedmiot ${i + 1}: SKU może zawierać do 32 znaków`,
            });
          if (!item.quantity || parseInt(item.quantity) <= 0 || parseInt(item.quantity) >= 9999)
            return this.infoSnackBar({
              message: `Przedmiot ${i + 1}: Ilość musi być z przedziału od 1 do 9999`,
            });
          if (item.color && item.color.length > 64)
            return this.infoSnackBar({
              message: `Przedmiot ${i + 1}: Kolor może zawierać do 64 znaków`,
            });
        }

        this.$emit('submit', this.itemsCopy);
      },
    },
    mounted() {},
    created() {},
  };
</script>

<style scoped>
  .v-btn--outlined {
    border: thin solid #ffc400 !important;
  }
  .v-btn--pretend--disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
</style>
<style>
  .no-padding-item .v-input__slot {
    padding-left: 0px !important;
    padding-right: 0px !important;
    background: inherit !important;
  }
  .no-padding-item .v-select__selection--comma {
    width: 100%;
    max-width: 100%;
  }
  table > tbody > tr.v-data-table__expanded.v-data-table__expanded__row {
    background: #eeeeee !important;
  }
  table > thead > tr > th {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  table > tbody > tr > td {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .v-application--is-ltr .v-text-field .v-input__append-inner {
    margin-top: 0px !important;
  }
</style>
