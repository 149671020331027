<template>
  <v-dialog v-model="isActive" persistent :width="width">
    <v-card>
      <v-toolbar flat color="black" dark>
        <v-toolbar-title> Ilość otrzymanych przedmiotów </v-toolbar-title>
        <v-spacer />
      </v-toolbar>

      <v-card-text class="pt-5 pb-1 grey--text text--darken-4">
        <v-slider
          v-model="confirmItems"
          :max="parseInt(this.item.quantity) || 0"
          :min="0"
          color="green lighten-1"
          track-color="error"
          thumb-label="always"
          hide-details
        >
          <template v-slot:append>
            <v-text-field
              v-model="confirmItems"
              label="Ilość"
              type="text"
              :maxlength="(parseInt(item.quantity) || 0).toString().length"
              single-line
              hide-details
              dense
              class="mt-0 pt-0"
              style="width: 52px"
            ></v-text-field>
          </template>
        </v-slider>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="amber accent-3"
          class="grey--text text--darken-4"
          outlined
          v-tilt
          x-large
          @click="isActive = false"
        >
          Anuluj
        </v-btn>

        <v-spacer />

        <v-btn
          color="amber accent-3"
          class="grey--text text--darken-4"
          v-tilt
          x-large
          @click="submit"
        >
          Zapisz
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'EditTransportConfirmItemsDialog',
    components: {},
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      item: {
        type: Object,
      },
      width: {
        type: String,
        default: '400px',
      },
    },
    data: () => ({
      confirmItems: 0,
    }),
    computed: {
      isActive: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
    },
    watch: {},
    methods: {
      submit: function () {
        this.$emit('submit', this.confirmItems, this.item);
      },
    },
    mounted() {},
    created() {
      this.confirmItems =
        this.item && this.item.quantity
          ? this.item.received == true || parseInt(this.item.received) >= parseInt(this.item.quantity)
            ? (this.confirmItems = parseInt(this.item.quantity))
            : parseInt(this.item.received)
            ? parseInt(this.item.received)
            : 0
          : 0;
    },
  };
</script>

<style scoped>
  .v-btn--outlined {
    border: thin solid #ffc400 !important;
  }
  .v-btn--pretend--disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
</style>
<style>
  table > tbody > tr.v-data-table__expanded.v-data-table__expanded__row {
    background: #eeeeee !important;
  }
  table > thead > tr > th {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  table > tbody > tr > td {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .v-application--is-ltr .v-text-field .v-input__append-inner {
    margin-top: 0px !important;
  }
</style>
